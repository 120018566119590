import React, { type FC } from 'react'
import { type CardBodyProps } from '../utils/types'

const CardBody: FC<CardBodyProps> = ({ data, onClick }) => {
  const { cPIssuanceId, tradeDate, settleDate, security, counterpartyName, notionalQty, discountRate } = data
  return (
        <div onClick={onClick} className="w-full p-4 whitespace-nowrap overflow-hidden overflow-ellipsis">
            <p><strong>Issuance ID:</strong> {cPIssuanceId}</p>
            <p><strong>Notional Qty:</strong> {notionalQty}</p>
            <p><strong>Discount Rate:</strong> {discountRate}</p>
            <p><strong>Security:</strong> {security}</p>
            <p><strong>Trade Date:</strong> {tradeDate}</p>
            <p><strong>Settle Date:</strong> {settleDate}</p>
            <p><strong>Counterparty Name:</strong> {counterpartyName}</p>
        </div>
  )
}

export default CardBody
