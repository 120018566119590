import { gql } from '@apollo/client'
import { ASSET_FRAGMENT, BLOTTER_FRAGMENT, RAW_ASSET_DETAILS_FRAGMENT } from '../fragments'

export const CP_SUBMIT_FOR_APPROVAL = gql`
    mutation CPIssuanceForApproval($id_list: String!) {
        submitCPIssuanceForApproval(id_list: $id_list) {
            cpIssuanceId
            status
            error
        }
    }
`
export const CP_COMPLIANCE_APPROVAL = gql`
    mutation CPIssuanceProgramCompliance($id_list: String!) {
        approveCPIssuanceProgramCompliance(id_list: $id_list) {
            cpIssuanceId
            status
            error
        }
    }
`
export const CP_SUBMIT_WITH_ADC_ID_CREATE = gql`
    mutation SubmitFRAndCreateADCId(
        $cp_id_list: String!
        $created_by: String!
        $note: String
        $fr_code: String!
        $designated_cash: Float!
        $pe_override: Boolean
        $left_cpty_code: Int!
        $right_cpty_code: Int!
    ) {
        submitAndCreateADCFundingRequest(
            cpIdList: $cp_id_list, 
            createdBy: $created_by, 
            frCode: $fr_code,
            note: $note,
            designatedCash: $designated_cash,
            peOverride: $pe_override,
            leftCptyCode: $left_cpty_code,
            rightCptyCode: $right_cpty_code
        ){
            status
            cpIssuanceId
            fundingRequestADCId
            error
        }
    }
`
export const FR_UPDATE = gql`
    mutation UpdateFR(
        $fr_id: Int!
        $updated_by: String!
        $old_status: String!
        $new_status: String!
        $note: String
    ) {
        updateFRStatus(
            frId: $fr_id,
            updatedBy: $updated_by,
            oldStatus: $old_status,
            newStatus: $new_status,
            note: $note
        ){
            status
            frId
            error
        }
    }
`

export const FR_SOFT_DELETE = gql`
    mutation WithdrawFR(
        $fr_id: Int!
        $updated_by: String!
    ) {
        withdrawFR(
            frId: $fr_id,
            updatedBy: $updated_by
        ){
            status
            frId
            error
        }
    }
`

export const UPLOAD_FILE = gql`
    ${BLOTTER_FRAGMENT}
    mutation UploadFile(
        $file_content: FileContent!
        $funding_request_id: String!
        ) {
        uploadFile(
            fileContent: $file_content,
            fundingRequestID: $funding_request_id
        ) {
            status
            message
            records{
                ...BlotterFragment
            }
        }
    }
`

export const ASSETS_GENERATION = gql`
    mutation GenerateAssetPackage(
        $assets_info: [UploadRecordInput!]!
    ) {
        generateAssetPackage(
            assetsInfo: $assets_info
        ) {
            asset {
                TRS {
                    Principal
                    DollarAccrued
                    TerminationStructureMtyDate
                    TenorDays
                    TRSFixedRate
                    TRSFixedDollarRec
                    ...AssetFragment
                }
                Treasuries {
                    Principal
                    DollarAccrued
                    ...AssetFragment
                }
                SLA {
                    TerminationStructureMtyDate
                    TenorDays
                    MarginPct
                    Rate
                    Collateral
                    LoanFee
                    ...AssetFragment
                }
            }
            aggregate {
                TRS {
                    TradeDate
                    SettleDate
                    Notional
                    Principal
                    MarketValue
                    TRSFixedDollarRec
                    TRSFixedRate
                    StructureMtyDate
                }
                Treasuries {
                    TradeDate
                    SettleDate
                    Notional
                    Principal
                    MarketValue
                }
                SLA {
                    TradeDate
                    SettleDate
                    TerminationDate
                    Rate
                    Notional
                    MarketValue
                    Collateral
                    LoanFee
                    MarginPctNumerator
                    WeightedMarginPct
                }
            }
            status
            message
        }
    }
    ${ASSET_FRAGMENT}
`
export const REALLOC_CREATION = gql`
    mutation CreateReallocFundingRequest(
        $realloc_detail: ReallocDetailsInput!
        $funding_request_id: String!
        $updated_by: String!
    ) {
        createReallocFundingRequest(
            realloDetails: $realloc_detail,
            fundingRequestID: $funding_request_id,
            updatedBy: $updated_by
        ) {
            status
            message
        }
    }
`

export const SAVE_UPDATED_FIELDS = gql`
    ${RAW_ASSET_DETAILS_FRAGMENT}
    mutation StoreSavedFRDataToDatabase(
        $updated_fields: [UpdatedFieldsInputs!]!
        $updated_fields_summary: UpdatedFieldsSummary
        $batch_unique_id: String!
        $updated_by: String!
        $funding_request_id: Int
    ){
        storeSavedFRDataToDatabase(
            updatedFields: $updated_fields,
            updatedFieldsSummary: $updated_fields_summary,
            batchUniqueId: $batch_unique_id,
            updatedBy: $updated_by
            fundingRequestId: $funding_request_id
        ) {
            status
            message
            data {
                ...RawAssetDetailsFragment
            }
        }
    }
`

export const NOTIFY_COUNTERPARTY = gql`
    mutation NotifyCounterParty(
        $batch_unique_id: String!
        $notify_status: String
        $updated_by: String!
    ){
        notifyCounterParty(
            batchUniqueId: $batch_unique_id,
            notifyStatus: $notify_status,
            updatedBy: $updated_by
        ) {
            status
            message
        }
    }
`

export const AMEND_ASSET_STATUS = gql`
    mutation AmendAssetStatus(
        $process_requested: String!
        $batch_unique_id: String
        $funding_request_id: Int
        $approval_status: String!
        $updated_by: String!
        $note: String
    ){
        amendAssetStatus(
            processRequested: $process_requested,
            batchUniqueId: $batch_unique_id,
            fundingRequestId: $funding_request_id
            approvalStatus: $approval_status,
            updatedBy: $updated_by,
            note: $note
        ) {
            status
            message
        }
    }
`
