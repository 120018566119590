import React, { useState, type FC } from 'react'
import FundingRequestDetails from './FundingRequestDetails'
import { extractCPDebtIssuanceRecord, extractLoanFromLenderRecord } from '../utils/textValidations'
import type { CPDebtIssuanceRecord, LoanFromLenderRecord, FundingRequestTabProps } from '../utils/types'

const FundingRequestTab: FC<FundingRequestTabProps> = ({
  fundingRequestData
}) => {
  const [noteText, setNoteText] = useState<string>('')
  const [cpExpand, setCpExpand] = useState(false)

  if (!fundingRequestData) {
    return <p>No data available</p>
  }

  const debtIssuanceRecord: CPDebtIssuanceRecord = extractCPDebtIssuanceRecord(fundingRequestData?.fundingRecords)
  const loanFromLenderRecord: LoanFromLenderRecord = extractLoanFromLenderRecord(fundingRequestData?.fundingRecords)

  return (
    <div className='p-4'>
      <FundingRequestDetails
        data={fundingRequestData}
        debtIssuanceRecord={debtIssuanceRecord}
        loanFromLenderRecord={loanFromLenderRecord}
        cpExpand={cpExpand}
        setCpExpand={setCpExpand}
        isApproval={false}
        noteText={noteText}
        setNoteText={setNoteText}
        isTab={true}
      />
    </div>
  )
}

export default React.memo(FundingRequestTab)
