import React, { type FC } from 'react'
import { type FundingRequestNoteProp } from '../../pages/Ops/utils/types'
import { formatEmailToName } from '../../utility/formatting'

const isLatest = (key: number) => key === 0

const isNoteBy = (key: string) => key === 'by'

const FRNote: FC<FundingRequestNoteProp> = ({ note, show }) => {
  return (
    <div className='space-x-0'>
        {note?.map((note, index) => (
            <div key={index} className='flex'>
                {Object.entries(note).slice(1).map(([key, value]) => {
                  const isKeyNoteBy = isNoteBy(key)
                  const shouldShow = show || isLatest(index)
                  const Tag = !isKeyNoteBy ? 'strong' : 'p'
                  const className = !isKeyNoteBy ? 'font-semibold text-sm ml-2' : 'text-sm text-gray-500'

                  return shouldShow
                    ? (
                        <div className="flex" key={key}>
                            <Tag className={className}>{isKeyNoteBy ? formatEmailToName(value) : value}</Tag>
                        </div>
                      )
                    : null
                })}
            </div>
        ))}
    </div>
  )
}

export default FRNote
