import React, { type FC } from 'react'
import { type FundingRequestTestProps } from '../utils/types'
import { formatKey } from '../../../utility/formatting'

interface TestResultProps {
  result: boolean | null
  title: string
  className?: string
}

const TestResult: FC<TestResultProps> = ({ result, title, className = '' }) => {
  let color, text

  if (result === null || result === undefined) {
    color = 'bg-gray-300 text-black'
    text = 'N/A'
  } else if (result) {
    color = 'bg-green-300 text-black'
    text = 'PASS'
  } else {
    color = 'bg-red-300 text-black'
    text = 'FAIL'
  }

  return (
    <div className={`flex justify-between items-center py-1 ${className}`}>
      <span className="font-semibold text-sm truncate">{formatKey(title)}: </span>
      <span className={`font-semibold text-sm py-1 px-2 rounded text-center w-20 ${color}`}>{text}</span>
    </div>
  )
}

const FRTestSection: FC<FundingRequestTestProps> = ({ data }) => {
  const testEntries = Object.entries(data || {})

  return (
    <div className="flex flex-col border rounded px-2 mt-4">
      {testEntries.slice(1).map(([key, value], index, array) => (
        <TestResult key={index} result={value} title={key} className={index === array.length - 2 ? 'border-b border-gray-400' : ''}/>
      ))}
    </div>
  )
}

export default FRTestSection
