import React from 'react'
import FundingRequest from '../../pages/Ops/funding-request'
import AvatarProfile from '../../components/avatar'

const OpsDashboard = () => {
  return (
        <div className='w-screen'>
            <AvatarProfile />
            <FundingRequest status='NEW' stage='Initial'/>
        </div>
  )
}

export default OpsDashboard
