import React, { useState } from 'react'
import FRNote from '../fr-note'
import expandicon from '../../utility/img/down.png'
import unexpandicon from '../../utility/img/up.png'
import { type ShowNotesProps } from '../../utility/types'

const ShowNotes: React.FC<ShowNotesProps> = ({
  data,
  defaultExpand = false
}) => {
  const handleShowNotes = () => {
    if (showNotes) {
      setShowNotes(false)
    } else {
      setShowNotes(true)
    }
  }
  const [showNotes, setShowNotes] = useState(defaultExpand)

  return (
    <div className='flex flex-col border border-gray-200 p-2 mt-2 rounded-lg w-full'>
      <div className='flex flex-row'>
        <h4 className="font-bold">Notes</h4>
        <button type='button' onClick={handleShowNotes} className='ml-auto'>
          <img src={showNotes ? unexpandicon : expandicon} alt="Expand or collapse notes" width={24} height={24}/>
        </button>
      </div>
      <div className="overflow-auto max-h-32">
        <FRNote note={data} show={showNotes}/>
      </div>
    </div>
  )
}

export default ShowNotes
