import { gql } from '@apollo/client'

export const CP_FRAGMENT = gql`
  fragment CPFragment on CP {
    cPIssuanceId
    status
    security
    price
    tradeDate
    settleDate
    cusip
    discountRate
    counterpartyName
    issuerName
    notionalQty
    proceeds
    maturityDate
    cPDiscountAmount
    aDC
    cpPair {
      cpLeft
      cpRight
    }
  }
`

export const ISSUER_FRAGMENT = gql`
  fragment IssuerFragment on Issuer {
    displayName
  }
`

export const NOTE_FRAGMENT = gql`
  fragment NoteFragment on Note {
    by
    text
  }
`

export const BLOTTER_FRAGMENT = gql`
  fragment BlotterFragment on UploadRecord {
    dealer
    tradeDate
    settleDate
    structureMtyDate
    cusip
    notional
    cleanPrice
    priceAccrued
    hairCut
    dirtyPrice
    repoRate
    tRSFixedRate
    inflationFactor
    secType
    side
  }
`

export const ASSET_FRAGMENT = gql`
    fragment AssetFragment on AssetData {
        Dealer
        TradeDate
        SettleDate
        Cusip
        Notional
        CleanPrice
        PriceAccrued
        DirtyPrice
        MarketValue
        SectionType
    }
`

export const FUNDING_RECORD_FRAGMENT = gql`
  fragment FundingRecordFragment on FundingRecord {
    issuer
    bankName
    fundingDate
    maturityDate
    tenorDays
    faceAmount
    proceeds
    discountRateTotal
    discountAmount
    moodysRating
    sAndPRating
    companyName
    principal
    interestRate
    interestAmount
    agreement
    amountDueAtMaturity
  }
`

export const FUNDING_REQUEST_TEST_FRAGMENT = gql`
  fragment FundingRequestTestFragment on FundingRequestTest {
    assetCfSufficiencyTest
    approvedTransactionAgtTest
    transactionCptyRatingTest
    transactionCptyMinRating
    transactionCptyRatingsOverride
    assetCoRepTest
    opGuidelinesAssetSpecificReqComplianceTest\
    assetPassFail
  }
`

export const FUNDING_REQUEST_SUM_FRAGMENT = gql`
  fragment FundingRequestSumFragment on FundingRequestSummaryData {
    status
    fundingRequestCode
    fundingRequestADCId
    cpIssuancesId
    timeSubmitted
    submittedBy
  }
`

export const FUNDING_REQUEST_FRAGMENT = gql`
  fragment FundingRequestFragment on FundingRequest {
    designatedCash
    fundingRequestCode
    cpIssuances {
      ...CPFragment
    }
    fundingRecords {
      ...FundingRecordFragment
    }
    fundingRequestTest{
      ...FundingRequestTestFragment
    }
    notes {
      ...NoteFragment
    }
  }
  ${CP_FRAGMENT}
  ${NOTE_FRAGMENT}
  ${FUNDING_RECORD_FRAGMENT}
  ${FUNDING_REQUEST_TEST_FRAGMENT}
`

export const RAW_ASSET_SUM_FRAGMENT = gql`
  fragment RawAssetSumFragment on AssetSums {
    AssetType
    BatchFileName
    BatchUniqueId
    BatchId
    MarketDataComplete
    OmsDataComplete
    RiskCalcComplete
    ClientAddressEmail
    CreatedDate
    BasketOverall
    ApprovalStatus
    FundingRequestCode
    CounterParty
    AssetsDisable
    TotalEstimatedNotional
    TotalAssetCount
    TotalQtyFI
    TotalQtyEqty
    ReconcileIndicator
    ReconcileCount
  }
`

export const RAW_ASSET_DETAILS_FRAGMENT = gql`
  fragment RawAssetDetailsFragment on RawAssetInfos {
    UniqueBatchId
    Ticker
    Price
    PrimaryExchange
    SecurityType
    Notional
    EarlyTerminationDate
    TraderPrice
    MarketValue
    CountryRegion
    TrsFinancingRate
    MaturityDate
    Moody
    SAndP
    Fitch
    Isin
    BuyOrSell
    Name
    Quantity
    Currency
    AssetType
    MarketDataComplete
    OmsDataComplete
    RiskCalcComplete
    CreatedDate
    Counterparty
    TradeDate
    EffectiveDate
    ScheduledMaturityDate
    FinalSettlementDate
    SecurityTypeCheck
    NotionalExposure
    AvgVol20D
    SinglePosAgainstCpty
    SinglePosAgainstCapMarketFirm
    Liquidity
    OutstandingShares
    MarketCapLessThanB
    Overall
    BasketOverall
    ProjectedInitialMargin
    LiquidityCptyTotal
    CounterpartyId
    EventNotes {
      ...NoteFragment
    }
    CheckTradeDate
    CheckEffectiveDate
    CheckScheduledMaturityDate
    CheckFinalSettlementDate
    CheckEarlyTerminationDate
    FundingRequestCode
    MainAccountAllocation
  }
  ${NOTE_FRAGMENT}
`

export const FR_ATTACHED_ASSET_DETAILS_FRAGMENT = gql`
  fragment FRAttachedAssetDetailsFragment on RawAssetInfos {
    AssetType
    Currency
    Name
    BuyOrSell
    Isin
    TrsFinancingRate
    Quantity
    Counterparty
    TradeDate
    EffectiveDate
    Overall
    SecurityTypeCheck
    BasketOverall
    ScheduledMaturityDate
    FinalSettlementDate
    Price
    ProjectedInitialMargin
    LiquidityCptyTotal
    CounterpartyId
    EventNotes {
      ...NoteFragment
    }
    Ticker
    PrimaryExchange
    Notional
    SecurityType
    EarlyTerminationDate
    CheckEarlyTerminationDate
    TraderPrice
    MarketValue
    CountryRegion
    MaturityDate
    MainAccountAllocation
  }
  ${NOTE_FRAGMENT}
`

export const FR_ATTACHED_ASSET_SUM_FRAGMENT = gql`
  fragment FRAttachedAssetSumFragment on AssetSummaryData {
    assetCashflows {
        TRSIncome
        InvestmentInterest
        ADC
        AssetAcquisitionCost
        Total
      }
      liabilityCashflows {
        DebtCost
        FinanceCost
        Total
      }
      overall {
        NetExcess
        RegulatoryCapitalTest
      }
  }
`
