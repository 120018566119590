import React, { type FC } from 'react'
import { Sidebar } from 'flowbite-react'
import nwclogo from '../../utility/img/nwc-square.png'
import { type SidebarMenuProps } from '../../utility/types'
import { useLocation } from 'react-router-dom'

const SidebarMenu: FC<SidebarMenuProps> = ({ menuItems, auth, msalInstance }) => {
  const location = useLocation()
  const isActive = (path: string) => location.pathname === path
  return (
    <div>
      <Sidebar aria-label="NWC Sidebar" className='p-2 w-56 min-h-screen border-solid border-r-2'>
        <Sidebar.Logo
          href="#"
          img={nwclogo}
          imgAlt="NWC logo"
          className="max-w-[40%]"
        >
          <p>Tradewinds</p>
        </Sidebar.Logo>
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {Object.entries(menuItems).map(([key, value]) => (
                <Sidebar.Item
                  href={value}
                  key={key}
                  className={`hover:bg-slate-200 rounded-lg ${isActive(value) ? 'bg-slate-300' : ''}`}
                >
                  <p>{key}</p>
                </Sidebar.Item>
            ))}
            {auth && <Sidebar.Item
                  key='sign-out'
                  onClick={async () => { await msalInstance.logout() }}
                  className='hover:bg-slate-200 rounded-lg'
                >
                  Sign Out
            </Sidebar.Item>}
            </Sidebar.ItemGroup>

            <Sidebar.ItemGroup className='min-h-fit'>
              <Sidebar.Item className='px-3 text-sm text-gray-500 fixed bottom-0'>
                Version 1.1.1
              </Sidebar.Item>
            </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
  </div>
  )
}

export default SidebarMenu
