import React, { useState, useCallback } from 'react'
import Card from '../../../components/card'
import FundingRequestCard from '../funding-request/FundingRequestCard'
import Message from '../../../components/message'
import { GET_FUNDING_REQUEST_BY_STATUS } from '../../../components/graphql/queries'
import {
  type PopupPropsFR,
  type CardBodyCompProps,
  type FRprops,
  type FundingRequestSum
} from '../utils/types'
import PopupScreen from '../../Cap-pc/pop-up'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'

const MultiTabFundingRequest = ({ status, stage }: FRprops) => {
  const [message, setMessage] = useState<string | null>(null)
  const [messageColor, setMessageColor] = useState<'red' | 'green' | 'yellow'>('green')

  const { loading, error, data, refetch, QueryWithRetryComponent } = useQueryWithRetry({
    query: GET_FUNDING_REQUEST_BY_STATUS,
    options: {
      variables: { status, from_date: '2024-01-01' }
    }
  })

  const renderCardBodyComponent = useCallback(
    (fundingRequest: FundingRequestSum, cardBodyProps: CardBodyCompProps) => {
      return <FundingRequestCard data={fundingRequest} {...cardBodyProps} status={fundingRequest.status} />
    },
    []
  )

  const renderPopupComponent = useCallback(
    (fundingRequestId: number, popupProps: PopupPropsFR) => {
      return (
        <PopupScreen
          fundingRequestADCId={fundingRequestId}
          stage={stage}
          setMessage={setMessage}
          setMessageColor={setMessageColor}
          {...popupProps}
        />
      )
    },
    [setMessage, setMessageColor, stage]
  )

  if (loading || error) return <QueryWithRetryComponent />

  return (
    <>
      {message && <Message message={message} color={messageColor} clearMessage={() => { setMessage('') }} />}
      <div className="flex flex-wrap justify-around">
        {data?.getFundingRequestByStatus && data.getFundingRequestByStatus.length > 0
          ? [...data.getFundingRequestByStatus]
              .sort((a: FundingRequestSum, b: FundingRequestSum) =>
                new Date(b.timeSubmitted).getTime() - new Date(a.timeSubmitted).getTime()
              )
              .map((fundingRequest) => (
                fundingRequest && (
                  <Card
                    key={fundingRequest.fundingRequestADCId}
                    refetch={refetch}
                    CardBodyComponent={(cardBodyProps) => renderCardBodyComponent(fundingRequest, cardBodyProps)}
                    PopupComponent={(popupProps) => renderPopupComponent(fundingRequest.fundingRequestADCId, popupProps)}
                  />
                )
              ))
          : <p>No data found</p>}
      </div>
    </>
  )
}

export default React.memo(MultiTabFundingRequest)
