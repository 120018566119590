import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_RAW_ASSET_DETAILS_BY_ID, GET_AVALIBLE_FR_WITH_COUNTERPARTY_ID } from '../../../components/graphql/queries'
import { AMEND_ASSET_STATUS, NOTIFY_COUNTERPARTY, SAVE_UPDATED_FIELDS } from '../../../components/graphql/mutations'
import { transformAssetData, formInputSessionData, getEditableFieldsForAssetType } from '../utils/formatting'
import { DEFAULT_INPUT_WRAP_DATA, TRADER_INPUT_FIELDS_MAP } from '../../../utility/constant'
import type { CombinedRecordType, InputAssetData, RiskCapTraderSharedAssetDetailsHookProps, NoteType } from '../../../utility/types'
import { getUpdatedFields, getUpdatedSummaryFields, amendFRAssetStatus } from '../utils/assetBucketsHelpers'
import { useGraphData } from '../../../useGraphData'
import { handleRecordRemove } from '../../../components/records-table/shared'
import { DATACHECKINGFIELDS, DATAVALIDATIONFIELDS } from '../utils/constant'
import type { FrOption } from '../utils/types'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'

export const useAssetDetails = ({
  batchId,
  batchUniqueId,
  assetType = 'EQTY',
  setMessage,
  setMessageColor,
  status: approvalStatus,
  onClose,
  basketOverall = false,
  updateError,
  fundingRequestCode = null,
  reconcileIndicator = 0
}: RiskCapTraderSharedAssetDetailsHookProps) => {
  const [records, setRecords] = useState<CombinedRecordType[]>([])
  const originalDataRef = useRef<{ originalRecords: CombinedRecordType[], originalInputData: InputAssetData, originalFRCode: string | null } | null>(null)
  const initialRecord = records.length > 0 ? records[0] : null
  const initialFormData = TRADER_INPUT_FIELDS_MAP(assetType).reduce<InputAssetData>((formData, field) => {
    if (initialRecord && field.name in initialRecord) {
      formData[field.name as keyof InputAssetData] = initialRecord[field.name as keyof CombinedRecordType] as string
    } else {
      formData[field.name as keyof InputAssetData] = ''
    }
    return formData
  }, DEFAULT_INPUT_WRAP_DATA)
  const [inputData, setInputData] = useState<InputAssetData>(initialFormData)
  const [riskOverall, setRiskOverall] = useState<boolean>(basketOverall)
  const [isDataReady, setIsDataReady] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [dataChanged, setDataChanged] = useState<boolean>(false)
  const [ableToProceed, setAbleToProceed] = useState<boolean>(false)
  const [isMutating, setIsMutating] = useState<boolean>(false)
  const [isSecondaryMutating, setIsSecondaryMutating] = useState<boolean>(false)
  const [hasFlag, setHasFlag] = useState<boolean>(false)
  const [isConfirmationDialogActive, setIsConfirmationDialogActive] = useState<boolean>(false)
  const [isRemoveRecordModalOpen, setIsRemoveRecordModalOpen] = useState(false)
  const [indexToRemove, setIndexToRemove] = useState<number | null>(null)
  const [FRDropDownOptions, setFRDropDownOptions] = useState<FrOption[]>([])
  const [recalcMessage, setRecalcMessage] = useState<string | null>(null)
  const [recalcMessageColor, setRecalcMessageColor] = useState<'red' | 'green' | 'yellow'>('green')
  const editableField = getEditableFieldsForAssetType(assetType)
  const [graphData] = useGraphData()
  const [traderNote, setTraderNote] = useState<string>('')
  const [counterpartyId, setCounterpartyId] = useState<number | null>(null)
  const [displayingNotes, setDisplayingNotes] = useState<NoteType[]>([])
  const [selectedFRCode, setselectedFRCode] = useState<string | null>(fundingRequestCode ?? null)
  const [selectedFRID, setSelectedFRID] = useState<number | null>(null)

  // Mutations
  const [saveUpdatedFieldsMutation] = useMutation(SAVE_UPDATED_FIELDS)
  const [amendFRAssetStatusMutation] = useMutation(AMEND_ASSET_STATUS)
  const [notifyCounterpartyMutation] = useMutation(NOTIFY_COUNTERPARTY)

  // Query
  const { data: assetDetailsData, loading, error, refetch: assetDetailsRefetch, QueryWithRetryComponent } = useQueryWithRetry({ query: GET_RAW_ASSET_DETAILS_BY_ID, options: { variables: { batchId } } })
  const [getFR, { data: frData, loading: frLoading, error: frError }] = useLazyQuery(GET_AVALIBLE_FR_WITH_COUNTERPARTY_ID, {
    variables: { counterpartyId },
    fetchPolicy: 'cache-and-network'
  })

  const handleRecordRemoval = (uniqueBatchId: number) => {
    setIndexToRemove(uniqueBatchId)
    setIsRemoveRecordModalOpen(true)
  }

  const confirmRecordRemoval = async () => {
    if (indexToRemove !== null) {
      handleRecordRemove<CombinedRecordType>(setRecords, indexToRemove)
      setIndexToRemove(null)
    }
    setIsRemoveRecordModalOpen(false)
  }

  const getPrimaryLabel = useMemo(() => {
    if (readOnly) return 'Link Intercompany Loan'
    if (!ableToProceed) return 'Save & Recalc'
    if (reconcileIndicator === 1) return 'Notify CounterParty'
    return riskOverall ? 'Proceed' : 'Proceed with exceptions'
  }, [ableToProceed, records])

  const hasRecordDataChanged = (): boolean => {
    if (!originalDataRef.current || records.length === 0) return false

    const originalRecordsMap = new Map(
      originalDataRef.current.originalRecords.map(record => [record.UniqueBatchId, record])
    )
    const currentRecords = records.filter((record) => !record.isRemoved)

    if (currentRecords.length !== originalRecordsMap.size) {
      return true
    }

    return records.some(record => {
      const originalRecord = originalRecordsMap.get(record.UniqueBatchId)
      if (!originalRecord) { // if UniqueBatchId does not exist, records changed
        return true
      }
      return editableField.some(field => {
        const currentValue = record[field as keyof CombinedRecordType]
        const originalValue = originalRecord[field as keyof CombinedRecordType]
        return currentValue !== originalValue
      })
    })
  }

  const hasInputDataChanged = (): boolean => {
    if (!originalDataRef.current || !inputData) return false

    return DATACHECKINGFIELDS.some((field) => inputData[field as keyof InputAssetData] !== originalDataRef.current?.originalInputData[field as keyof InputAssetData])
  }

  const hasFundingRequestChanged = (): boolean => {
    if (!originalDataRef.current || !selectedFRCode) return false
    const originalFRCode = originalDataRef.current.originalFRCode
    return originalFRCode ? !selectedFRCode.includes(originalFRCode) : true
  }

  const handleSaveUpdatedFields = async () => {
    const originalRecords = originalDataRef.current?.originalRecords ?? []
    const originalInputData = originalDataRef.current?.originalInputData ?? initialFormData

    const updatedFields = getUpdatedFields(records, originalRecords, editableField)
    const updatedFieldsSummaryInputs = getUpdatedSummaryFields(inputData, originalInputData)
    let message = ''
    let color = 'red'

    if ((Object.keys(updatedFields).length > 0 || updatedFieldsSummaryInputs) && dataChanged) {
      try {
        const response = await saveUpdatedFieldsMutation({
          variables: {
            updated_fields: updatedFields,
            updated_fields_summary: updatedFieldsSummaryInputs,
            batch_unique_id: batchUniqueId,
            updated_by: graphData?.mail,
            funding_request_id: selectedFRID
          }
        })
        if (response?.data?.storeSavedFRDataToDatabase?.data) {
          const updatedAssetDetailsData = response.data.storeSavedFRDataToDatabase.data
          const transformedData = transformAssetData(updatedAssetDetailsData)
          const inputFieldData = formInputSessionData(updatedAssetDetailsData)
          setRiskOverall(updatedAssetDetailsData[0].BasketOverall)
          setRecords(transformedData)
          setInputData(inputFieldData)
          originalDataRef.current = { originalRecords: transformedData, originalInputData: inputFieldData, originalFRCode: updatedAssetDetailsData[0].FundingRequestCode }
        }
        message = response?.data?.storeSavedFRDataToDatabase?.message ?? 'No fields have been updated.'
        color = response?.data?.storeSavedFRDataToDatabase?.status === 'success' ? 'green' : 'red'
      } catch (error) {
        const errorMessage = error ? String(error) : 'error'
        message = `Error saving updated fields: ${errorMessage}`
      }
    } else {
      message = 'No fields have been updated.'
    }
    return { message, color }
  }

  const areAllFieldsFilled = (): boolean => {
    if (
      records.some((record) =>
        editableField.some((field) => record[field as keyof CombinedRecordType] === '' || record[field as keyof CombinedRecordType] == null)
      )
    ) {
      return false
    }
    if (
      DATAVALIDATIONFIELDS.some((field) => {
        const value = inputData[field as keyof InputAssetData]
        return value === '' || value == null || (typeof value === 'string' && value.startsWith('1900'))
      })
    ) {
      return false
    }

    return true
  }

  const handleDiscard = async (): Promise<void> => {
    setIsSecondaryMutating(true)
    if (isConfirmationDialogActive) {
      setIsConfirmationDialogActive(false)
      if (originalDataRef.current) {
        setRecords(originalDataRef.current.originalRecords)
        setInputData(originalDataRef.current.originalInputData)
        setselectedFRCode(originalDataRef.current.originalFRCode)
      }
    }
    setIsSecondaryMutating(false)
    setMessage?.('')
    setRecalcMessage?.('')
    onClose?.()
  }

  const handleSaveRecalc = async (): Promise<void> => {
    setIsMutating(true)
    const { message, color } = await handleSaveUpdatedFields()
    setRecalcMessage(message)
    setRecalcMessageColor(color as 'red' | 'green' | 'yellow')
    setIsMutating(false)
  }

  const handleSaveExit = async () => {
    setIsMutating(true)
    const { message, color } = await handleSaveUpdatedFields()
    setMessage?.(message)
    setMessageColor?.(color as 'red' | 'green' | 'yellow')
    setIsMutating(false)
    onClose?.()
  }

  const handleCloseIconClick = () => {
    if (dataChanged) {
      setIsConfirmationDialogActive(true)
    } else {
      onClose?.()
    }
    setMessage?.('')
    setRecalcMessage?.('')
  }

  const handleDropdownChange = (selectedCode: string) => {
    const option = FRDropDownOptions.find(option => option.frCode === selectedCode)
    if (option) {
      const selectedID = option.frID
      setSelectedFRID(selectedID)
      setselectedFRCode(selectedCode)
    }
  }

  const fetchFRs = useCallback(() => {
    getFR()
  }, [getFR])

  const handleProceedClick = async () => {
    setIsMutating(true)
    if (reconcileIndicator === 1) {
      try {
        const { data: { notifyCounterParty: { message, status } } } = await notifyCounterpartyMutation({
          variables: {
            batch_unique_id: batchUniqueId,
            updated_by: graphData?.mail,
            notifyStatus: 'ASSET-READY-FROM-BEFORE-TRADER-CHG-FOR-RECON'
          }
        })
        setRecalcMessage?.(message)
        setRecalcMessageColor?.(status === 'failed' ? 'red' : 'green')
      } catch (error) {
        setRecalcMessage?.('Failed to notify CounterParty.')
        setRecalcMessageColor?.('red')
      }
      setIsMutating(false)
    } else {
      const approvalStatus = riskOverall ? 'TRADER-APPROVED' : 'TRADER-APPROVED-EXCEPTION'
      if (batchUniqueId) {
        await amendFRAssetStatus({
          amendFRAssetStatusMutation,
          batchUniqueId,
          approvalStatus,
          processRequested: 'CapMarket',
          updatedBy: graphData?.mail,
          note: traderNote,
          setMessage,
          setMessageColor
        })
      }
      setIsMutating(false)
      onClose?.()
    }
  }

  const handlePrimaryActionWithValidation = async () => {
    if (getPrimaryLabel?.toLowerCase().includes('exception') && !traderNote.trim()) {
      setRecalcMessage('Cannot proceed without notes since there is an exception. Please leave a note.')
      setRecalcMessageColor('yellow')
      return
    }
    if (ableToProceed) {
      await handleProceedClick()
    } else {
      handleSaveRecalc()
    }
  }

  const handleCancelClick = async () => {
    handleCloseIconClick()
  }

  useEffect(() => {
    if (frLoading || frError) return
    if (frData?.getFundingRequestOptionsByCounterpartyId) {
      setFRDropDownOptions(frData.getFundingRequestOptionsByCounterpartyId.fr_options)
    }
  }, [frData, frLoading, frError, FRDropDownOptions, setFRDropDownOptions])

  useEffect(() => {
    const handlePostSaveOperations = async () => {
      if (!isMutating) {
        await assetDetailsRefetch()
        if (isConfirmationDialogActive) {
          setIsConfirmationDialogActive(false)
        }
      }
    }

    handlePostSaveOperations()
  }, [isMutating, assetDetailsRefetch, onClose])

  useEffect(() => {
    if (assetDetailsData?.getRawAssetDetailsByBatchId?.length > 0) {
      const assetDetails = assetDetailsData?.getRawAssetDetailsByBatchId
      const transformedData = transformAssetData(assetDetails)
      const inputFieldData = formInputSessionData(assetDetails)
      const firstRecord = assetDetails[0]
      setCounterpartyId(firstRecord.CounterpartyId)
      setDisplayingNotes(firstRecord.EventNotes)
      setIsDataReady(firstRecord.MarketDataComplete && firstRecord.OmsDataComplete && firstRecord.RiskCalcComplete)
      setReadOnly(!approvalStatus?.includes('ASSET' || 'REJECTED') && ![1, 4].includes(reconcileIndicator))
      setRecords(transformedData)
      setInputData(inputFieldData)
      if (!originalDataRef.current) {
        originalDataRef.current = { originalRecords: transformedData, originalInputData: inputFieldData, originalFRCode: fundingRequestCode ?? null }
      }
    }
    if (error ?? updateError) {
      setRecalcMessage('An error occurred while fetching data')
      setRecalcMessageColor('red')
    }
  }, [assetDetailsData])

  useEffect(() => {
    const recordsChanged = hasRecordDataChanged()
    const formDataChanged = hasInputDataChanged()
    const fundingRequestChanged = hasFundingRequestChanged()
    const fieldsFilled = areAllFieldsFilled()
    setAbleToProceed(!recordsChanged && !formDataChanged && !fundingRequestChanged && fieldsFilled && !readOnly && isDataReady && !hasFlag)
    setDataChanged(recordsChanged || formDataChanged || fundingRequestChanged)
  }, [records, inputData, selectedFRCode, hasFlag])

  return {
    records,
    setRecords,
    inputData,
    setInputData,
    isDataReady,
    loading,
    error,
    QueryWithRetryComponent,
    isConfirmationDialogActive,
    setIsConfirmationDialogActive,
    isRemoveRecordModalOpen,
    setIsRemoveRecordModalOpen,
    handleRecordRemoval,
    confirmRecordRemoval,
    dataChanged,
    ableToProceed,
    isMutating,
    isSecondaryMutating,
    FRDropDownOptions,
    selectedFRCode,
    originalFRCode: originalDataRef?.current?.originalFRCode,
    editableField,
    handleDiscard,
    handleSaveExit,
    handlePrimaryActionWithValidation,
    handleCloseIconClick,
    handleDropdownChange,
    fetchFRs,
    handleCancelClick,
    getPrimaryLabel,
    readOnly,
    recalcMessage,
    recalcMessageColor,
    setRecalcMessage,
    setRecalcMessageColor,
    traderNote,
    setTraderNote,
    displayingNotes,
    setHasFlag
  }
}
