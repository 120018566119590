import { type UserRole } from './types'

export const menuItems: Record<UserRole, Record<string, string>> = {
  'Treasury.Trader': {
    Dashboard: '/dashboard',
    'Commercial Paper': '/treasury-trader',
    Notifications: '/liquid-notifications'
  },
  'ProgramCompliance.Approver': {
    Dashboard: '/dashboard',
    'Proposed Requests': '/program-compliance',
    'Cap Market Review': '/cap-program-compliance-view'
  },
  'ProgramCompliance.FinalApprover': {
    Dashboard: '/dashboard',
    'Initial Approved': '/management-program-compliance'
  },
  Administrator: {
    Dashboard: '/dashboard',
    'Commercial Paper': '/treasury-trader',
    'Proposed Requests': '/program-compliance',
    'Initial Approved': '/management-program-compliance',
    'Risk Review': '/risk-review',
    'Cap Market Review': '/cap-program-compliance-view',
    'Cap Market Final Review': '/cap-management-program-compliance',
    'Cap Market Assets': '/cap-market-trader-view',
    // 'Realloc FR Creation': '/realloc-fr-creation',
    Notifications: '/liquid-notifications'
  },
  'LiquidMarket.Trader': {
    Dashboard: '/dashboard'
    // 'Realloc FR Creation': '/realloc-fr-creation'
  },
  'CapitalMarket.Trader': {
    Dashboard: '/dashboard',
    'Cap Market Assets': '/cap-market-trader-view'
  },
  'Capital.Markets.Risk.Officer': {
    Dashboard: '/dashboard',
    'Risk Review': '/risk-review'
  }
}

export function getMenuItemsForRole (role: string): Record<string, string> {
  return menuItems[role as UserRole] || {}
}

export function getUserRoleMenu (userRoles: string[]): string {
  if (userRoles.includes('Administrator')) {
    return 'Administrator'
  }
  return userRoles[0] as UserRole || 'RoleUndefined'
}

export const issuerMenu = [
  'All Issuers',
  'Columbia',
  'Mackinac'
]
