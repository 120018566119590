import { useState } from 'react'
import type { CombinedRecordType, RecordInputRowProps } from '../../utility/types'
import type { BlotterRecord } from '../../pages/File-uploading/utils/types'
import TextInput from '../field-input'

const RecordInputRow = <T extends CombinedRecordType | BlotterRecord>({
  records,
  setRecords,
  initialRecordState = {},
  setShowInputRow,
  validateRecord = () => true
}: RecordInputRowProps<T>) => {
  const [newRecord, setNewRecord] = useState<Partial<T>>(initialRecordState)

  const handleInputChange = (field: string, value: string) => {
    if (isKeyOfNewRecord(field)) {
      setNewRecord((prev) => ({ ...prev, [field]: value }))
    }
  }

  const isKeyOfNewRecord = (key: unknown): key is keyof T => {
    return typeof key === 'string' && key in newRecord
  }

  const addNewRecord = () => {
    if (validateRecord(newRecord)) {
      const tempRecords = [...records, newRecord as T]
      setRecords?.(tempRecords)
      setNewRecord(initialRecordState)
      setShowInputRow?.(false)
    } else {
      alert('Please fill all fields correctly.')
    }
  }
  return (
    <tr>
      <th className="border p-2 text-center">
        <button
          className="text-white bg-rose-500 hover:bg-rose-600 transition duration-150 ease-in-out px-3 py-1 rounded-full"
          onClick={() => { setShowInputRow?.(false) }}>
        -
        </button>
        <button className="text-white bg-lime-500 hover:bg-lime-600 transition duration-150 ease-in-out ml-2 px-2.5 py-1 rounded-full" onClick={addNewRecord}>
        +
        </button>
      </th>
      {Object.keys(newRecord).map((key, i) => {
        if (isKeyOfNewRecord(key)) {
          return (
            <td key={i} className="border p-2 text-center">
              <TextInput
                type="text"
                inputClassName="flex-grow focus:ring-gray-100 focus:border-gray-300"
                value={String(newRecord[key] ?? '')}
                setValue={(value) => { handleInputChange(key, value) }}
              />
            </td>
          )
        } else {
          return null
        }
      })}
    </tr>
  )
}

export default RecordInputRow
