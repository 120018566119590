import React from 'react'
import { type ActionButtonProps } from '../../utility/types'

const ActionButton: React.FC<ActionButtonProps> = ({
  primaryLabel,
  secondaryLabel,
  primaryAction,
  secondaryAction,
  primaryLoading = false,
  secondaryLoading = false,
  primaryDisabled = false,
  secondaryDisabled = false,
  primaryButtonColor = 'bg-green-600 hover:bg-green-700 focus:ring-green-500',
  secondaryButtonColor = 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-gray-500'
}) => {
  return (
        <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${primaryButtonColor} ${primaryDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={primaryAction}
                disabled={primaryLoading || secondaryLoading || primaryDisabled}
            >
                {primaryLoading
                  ? (<div className="animate-spin text-center rounded-full h-4 w-4 mx-8 border-b-2 border-white"></div>)
                  : primaryLabel
                }
            </button>
            <button
                type="button"
                className={`mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm ${secondaryButtonColor}  ${secondaryDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={secondaryAction}
                disabled={primaryLoading || secondaryLoading || secondaryDisabled}
            >
                {secondaryLoading
                  ? (<div className="animate-spin rounded-full h-4 w-4 mx-8 border-b-2 border-black"></div>)
                  : secondaryLabel
                }
            </button>
        </div>
  )
}

export default React.memo(ActionButton)
