import React, { type FC } from 'react'
import FundingRequestItem from './FundingRequestItem'
import { type FullFundingRequestGroupProps } from '../utils/types'

const FundingRequestGroup: FC<FullFundingRequestGroupProps> = ({ status, fundingRequests, refetch, setMessage, setMessageColor }) => {
  return (
    <section className="mb-8">
      <h2 className="text-2xl mb-4">{status}</h2>
      <div className='flex flex-wrap'>
        {fundingRequests.map(fr => (
          <FundingRequestItem
            key={fr.fundingRequestCode}
            fundingRequest={fr}
            refetch={refetch}
            setMessage={setMessage}
            setMessageColor={setMessageColor}
            status={status}
          />
        ))}
      </div>
    </section>
  )
}

export default FundingRequestGroup
