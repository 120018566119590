import React, { type FC, type ChangeEvent, useRef, useEffect } from 'react'
import { type NotePadProps } from '../../utility/types'

const MiniNotepad: FC<NotePadProps> = ({ noteText, setNoteText, autoExpand = false, disabled = false, styleClass = 'w-full mt-2' }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteText(event.target.value)
    if (autoExpand) {
      adjustHeight()
    }
  }

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }

  useEffect(() => {
    if (autoExpand) {
      adjustHeight()
    }
  }, [noteText, autoExpand])

  return (
    <div className={styleClass}>
      <textarea
        ref={textareaRef}
        className="w-full h-full p-3 border-1
                   border-gray-200 rounded-md focus:ring-2
                   focus:ring-gray-100 focus:border-gray-300"
        value={noteText}
        onChange={handleChange}
        disabled={disabled}
        placeholder="Enter notes here..."
        style={autoExpand ? { overflow: 'hidden', resize: 'none' } : {}}
      />
    </div>
  )
}

export default MiniNotepad
