import React from 'react'
import CPInfo from '../../../components/cp-info'
import Info from './Info'
import type { FundingRequestDetailsProps } from '../utils/types'
import FRTestSection from './FundingRequestTest'
import MiniNotepad from '../../../components/notepad'
import ShowNotes from '../../../components/show-notes'

const FundingRequestDetails: React.FC<FundingRequestDetailsProps> = ({
  data,
  debtIssuanceRecord,
  loanFromLenderRecord,
  cpExpand,
  setCpExpand,
  isApproval,
  noteText,
  shouldShowSideNotes = false,
  shouldShowBottomNotes = false,
  isTab = false,
  setNoteText
}) => {
  const lenderName: string = loanFromLenderRecord?.lender || ''
  const notes = data.notes
  return (
    <>
      <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
        Funding Request Details
      </h3>
      <div className='flex flex-wrap items-center'>
        <div className='flex items-center mr-4'>
          <p className='font-semibold'>From: </p>
          <p className='px-2'>{data?.fundingRecords?.companyName}</p>
        </div>
        <div className='flex items-center mr-4'>
          <p className='font-semibold'>To: </p>
          <p className='px-2'>{data?.fundingRecords?.bankName}</p>
        </div>
        <div className='flex items-center mr-4'>
          <p className='font-semibold'>Funding Request ID:</p>
          <p className='px-2 bg-yellow-200'>{data?.fundingRequestCode}</p>
        </div>
        <div className='flex items-center'>
          <p className='font-semibold'>ADC Amount:</p>
          <p className='px-2'>{data?.designatedCash}</p>
        </div>
      </div>

      <p className='font-semibold'>Agreement: </p>
      <p className='text-sm'>{data?.fundingRecords?.agreement}</p>
      <div className="flex grow flex-col xl:flex-row">
        <div className="xl:w-1/2">
          {data?.cpIssuances && (
            <div className="border-2 border-gray-200 p-2 my-2 rounded-lg flex-grow">
              <CPInfo data={data.cpIssuances} setCpExpand={setCpExpand} />
            </div>
          )}

          <div className='flex flex-col w-full'>
            {debtIssuanceRecord && (
              <div className="border-2 border-gray-200 p-2 my-2 rounded-lg flex-grow">
                <Info data={[debtIssuanceRecord]} title="CP Debt Issuance to Market Investors" />
              </div>
            )}

            {!cpExpand && loanFromLenderRecord && (
              <div className="border-2 border-gray-200 p-2 my-2 rounded-lg flex-grow">
                <Info data={[loanFromLenderRecord]} title={`Loan from ${lenderName}`} />
              </div>
            )}
          </div>
        </div>

        {data?.fundingRequestTest && (
          <div className="xl:w-1/2 xl:ml-4 flex flex-col">
            <FRTestSection data={data.fundingRequestTest} />
            {cpExpand && loanFromLenderRecord && (
              <div className="border-2 border-gray-200 p-2 my-2 rounded-lg flex-grow mt-4">
                <Info data={[loanFromLenderRecord]} title={`Loan from ${lenderName}`} />
              </div>
            )}
            {!isTab && (
              isApproval
                ? (
                <MiniNotepad noteText={noteText} setNoteText={setNoteText} styleClass="w-full my-2 h-full" />
                  )
                : (
                <ShowNotes data={notes} />
                  )
            )}
            {shouldShowSideNotes && <ShowNotes data={notes} />}
          </div>
        )}
      </div>
      {shouldShowBottomNotes && <ShowNotes data={notes}/>}
    </>
  )
}

export default FundingRequestDetails
