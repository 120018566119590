import React, { useState, useCallback, useEffect, useRef } from 'react'
import { type CardProps } from '../../utility/types'

const Card: React.FC<CardProps> = (props) => {
  const {
    cpIssuanceId,
    refetch,
    showCheckBoxes,
    selectedCards,
    setSelectedCards,
    isCp,
    batchUniqueId,
    CardBodyComponent,
    PopupComponent,
    isClickable = true
  } = props
  const [showPopup, setShowPopup] = useState(false)
  const [isTextSelected, setIsTextSelected] = useState(false)
  const justClosedPopup = useRef(false)

  const onCheckChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (setSelectedCards && selectedCards && cpIssuanceId !== undefined) {
        if (e.target.checked) {
          setSelectedCards([...selectedCards, cpIssuanceId])
        } else {
          setSelectedCards(selectedCards.filter((id) => id !== cpIssuanceId))
        }
      }
    },
    [cpIssuanceId, setSelectedCards, selectedCards]
  )

  const openPopup = useCallback(() => {
    if (isClickable && !isTextSelected && !justClosedPopup.current) {
      setShowPopup(true)
      if (batchUniqueId) {
        console.log('Batch UniqueId', batchUniqueId)
      }
    }
  }, [isClickable, isTextSelected, refetch, batchUniqueId])

  const closePopup = useCallback(() => {
    setShowPopup(false)
    justClosedPopup.current = true
    refetch()
    setTimeout(() => {
      justClosedPopup.current = false
    }, 300)
  }, [refetch])

  const handleMouseUp = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement
      if (target instanceof HTMLInputElement && target.type === 'checkbox') {
        return
      }
      setTimeout(() => {
        const selectedText = window.getSelection()?.toString()
        if (!selectedText) {
          openPopup()
        }
      }, 0)
    },
    [openPopup]
  )

  const handleMouseDown = useCallback(() => {
    const selectedText = window.getSelection()?.toString()
    if (selectedText) {
      setIsTextSelected(true)
    } else {
      setIsTextSelected(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('selectionchange', handleMouseDown)
    return () => {
      document.removeEventListener('selectionchange', handleMouseDown)
    }
  }, [handleMouseDown])

  return (
    <div>
      <div
        {...(batchUniqueId ? { title: `Batch Unique ID: ${String(batchUniqueId)}` } : {})}
        className={`flex flex-wrap border-2 m-2 border-gray-200 max-w-sm rounded-md cursor-pointer hover:shadow-lg ${isClickable ? '' : 'opacity-50 cursor-not-allowed'} ${isCp ? 'w-80' : 'w-auto'}`}
        onMouseUp={handleMouseUp}
      >
        <div>
          {showCheckBoxes && setSelectedCards && selectedCards && cpIssuanceId !== undefined && (
            <input
              type="checkbox"
              checked={selectedCards.includes(cpIssuanceId)}
              onChange={onCheckChange}
              className="form-checkbox text-black border-black focus:border-black focus:ring focus:ring-black focus:ring-opacity-50 h-4 w-4 ml-2 mt-2"
            />
          )}
        </div>
        {CardBodyComponent && <CardBodyComponent onClick={openPopup} />}
        {PopupComponent && (
          <PopupComponent
            show={showPopup}
            onClose={closePopup}
          />
        )}
      </div>
    </div>
  )
}

export default React.memo(Card)
