import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { AMEND_ASSET_STATUS, SAVE_UPDATED_FIELDS } from '../../../components/graphql/mutations'
import { GET_RAW_ASSET_DETAILS_BY_ID } from '../../../components/graphql/queries'
import type { RawEqtyData, CombinedRecordType, InputAssetData, RawAssetData, RawBondData, RiskCapTraderSharedAssetDetailsHookProps, NoteType } from '../../../utility/types'
import { formInputSessionData, transformAssetData } from '../utils/formatting'
import { amendFRAssetStatus, getUpdatedFields } from '../../Cap-Trader/utils/assetBucketsHelpers'
import { RISK_BOND_EDITABLE_FIELDS } from '../utils/constants'
import { useGraphData } from '../../../useGraphData'
import { DEFAULT_INPUT_WRAP_DATA } from '../../../utility/constant'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'

export const useRiskAssetDetails = (
  {
    batchId,
    batchUniqueId,
    assetType,
    setMessage,
    setMessageColor,
    status = '',
    basketOverall = false,
    onClose,
    updateError,
    testDetails = false
  }: RiskCapTraderSharedAssetDetailsHookProps
) => {
  const [records, setRecords] = useState<CombinedRecordType[]>([])
  const [graphData] = useGraphData()
  const [riskOverall, setRiskOverall] = useState<boolean>(basketOverall)
  const originalDataRef = useRef<{ originalRecords: CombinedRecordType[] } | null>(null)
  const [inputData, setInputData] = useState<InputAssetData>(DEFAULT_INPUT_WRAP_DATA)
  const [isMutating, setIsMutating] = useState<boolean>(false)
  const [isSecondaryMutating, setIsSecondaryMutating] = useState<boolean>(false)
  const [dataChanged, setDataChanged] = useState<boolean>(false)
  const [recalcMessage, setRecalcMessage] = useState<string | null>(null)
  const [recalcMessageColor, setRecalcMessageColor] = useState<'red' | 'green' | 'yellow'>('green')
  const [isConfirmationDialogActive, setIsConfirmationDialogActive] = useState<boolean>(false)
  const enableEdit = assetType === 'BOND' && !/(RISK|COMPL)/.test(status)
  const [riskNote, setRiskNote] = useState<string>('')
  const [displayingNotes, setDisplayingNotes] = useState<NoteType[]>([])
  const preparingAsset = status?.includes('ASSET')
  const getPrimaryLabel = useMemo(() => {
    if (preparingAsset) return 'Save'
    return riskOverall ? 'Approve' : 'Approve with Exceptions'
  }, [status, records, riskOverall])

  const { data: assetDetailsData, loading, error, QueryWithRetryComponent } = useQueryWithRetry({ query: GET_RAW_ASSET_DETAILS_BY_ID, options: { variables: { batchId } } })
  const [saveUpdatedFieldsMutation] = useMutation(SAVE_UPDATED_FIELDS)
  const [amendFRAssetStatusMutation] = useMutation(AMEND_ASSET_STATUS)

  const hasRecordDataChanged = (): boolean => {
    if (!originalDataRef.current || records.length === 0) return false

    const originalRecordsMap = new Map(
      originalDataRef.current.originalRecords.map(record => [record.UniqueBatchId, record])
    )
    const currentRecords = records.filter((record) => !record.isRemoved)

    if (currentRecords.length !== originalRecordsMap.size) {
      return true
    }

    return records.some(record => {
      const originalRecord = originalRecordsMap.get(record.UniqueBatchId)
      if (!originalRecord) { // if UniqueBatchId does not exist, records changed
        return true
      }
      return RISK_BOND_EDITABLE_FIELDS.some(field => {
        const currentValue = record[field as keyof CombinedRecordType]
        const originalValue = originalRecord[field as keyof CombinedRecordType]
        return currentValue !== originalValue
      })
    })
  }

  const transformAndSetData = useCallback((data: Array<RawAssetData & RawBondData & RawEqtyData & InputAssetData>) => {
    const transformedData = transformAssetData(data, testDetails)
    const inputFieldData = formInputSessionData(data)

    setRecords(transformedData)
    setInputData(inputFieldData)
    if (!originalDataRef.current) {
      originalDataRef.current = { originalRecords: transformedData }
    }
  }, [])

  useEffect(() => {
    const handleError = (errorMessage: string) => {
      setRecalcMessage(errorMessage)
      setRecalcMessageColor('red')
    }

    if (assetDetailsData) {
      const rawAssets = assetDetailsData.getRawAssetDetailsByBatchId
      if (rawAssets?.length > 0) {
        transformAndSetData(rawAssets)
        setDisplayingNotes(rawAssets[0].EventNotes)
      }
    }

    if (error ?? updateError) {
      handleError('An error occurred while fetching data')
    }
  }, [assetDetailsData, error, updateError, setRecalcMessage, setRecalcMessageColor, transformAndSetData])

  const handleSaveUpdatedFields = async () => {
    const originalRecords = originalDataRef.current?.originalRecords ?? []
    const updatedFields = getUpdatedFields(records, originalRecords, RISK_BOND_EDITABLE_FIELDS)

    let message = ''
    let color = 'red'

    if (Object.keys(updatedFields).length > 0 && dataChanged) {
      try {
        const response = await saveUpdatedFieldsMutation({
          variables: {
            updated_fields: updatedFields,
            batch_unique_id: batchUniqueId,
            updated_by: graphData?.mail
          }
        })
        if (response?.data?.storeSavedFRDataToDatabase?.data) {
          const updatedAssetDetailsData = response.data.storeSavedFRDataToDatabase.data
          const transformedData = transformAssetData(updatedAssetDetailsData, testDetails)
          setRiskOverall(updatedAssetDetailsData[0].BasketOverall)
          setRecords(transformedData)
          originalDataRef.current = { originalRecords: transformedData }
        }
        message = response?.data?.storeSavedFRDataToDatabase?.message ?? 'No fields have been updated.'
        color = response?.data?.storeSavedFRDataToDatabase?.status === 'success' ? 'green' : 'red'
      } catch (error) {
        const errorMessage = error ? String(error) : 'error'
        message = `Error saving updated fields: ${errorMessage}`
      }
    } else {
      message = 'No fields have been updated.'
    }
    return { message, color }
  }

  const handleSave = async (): Promise<void> => {
    setIsMutating(true)
    const { message, color } = await handleSaveUpdatedFields()
    setRecalcMessage(message)
    setRecalcMessageColor(color as 'red' | 'green' | 'yellow')
    setIsMutating(false)
  }

  const handleSaveExit = async (): Promise<void> => {
    setIsMutating(true)
    const { message, color } = await handleSaveUpdatedFields()
    setMessage?.(message)
    setMessageColor?.(color as 'red' | 'green' | 'yellow')
    setIsMutating(false)
    setIsConfirmationDialogActive(false)
    onClose?.()
  }

  const handleDiscard = async (): Promise<void> => {
    setIsSecondaryMutating(true)
    if (isConfirmationDialogActive) {
      setIsConfirmationDialogActive(false)
      if (originalDataRef.current) {
        setRecords(originalDataRef.current.originalRecords)
      }
    }
    setIsSecondaryMutating(false)
    onClose?.()
  }

  const handlePrimaryClick = async () => {
    setIsMutating(true)
    const approvalStatus = riskOverall ? 'RISK-APPROVED' : 'RISK-APPROVED-EXCEPTION'
    batchUniqueId && await amendFRAssetStatus({
      amendFRAssetStatusMutation,
      batchUniqueId,
      approvalStatus,
      updatedBy: graphData?.mail,
      processRequested: 'CapMarket',
      note: riskNote,
      setMessage,
      setMessageColor
    })
    setIsMutating(false)
    onClose?.()
  }

  const handlePrimaryActionWithValidation = async () => {
    if (getPrimaryLabel.toLowerCase().includes('exception') && !riskNote.trim()) {
      setRecalcMessage('Cannot proceed without notes since there is an exception. Please leave a note.')
      setRecalcMessageColor('yellow')
      return
    }
    if (preparingAsset) {
      await handleSave()
    } else {
      handlePrimaryClick()
    }
  }

  const handleSecondaryClick = async () => {
    setIsSecondaryMutating(true)
    batchUniqueId && await amendFRAssetStatus({
      amendFRAssetStatusMutation,
      batchUniqueId,
      approvalStatus: 'RISK-REJECTED',
      processRequested: 'CapMarket',
      updatedBy: graphData?.mail,
      note: riskNote,
      setMessage,
      setMessageColor
    })
    setIsSecondaryMutating(false)
    onClose?.()
  }

  const handleCancel = async () => {
    onClose?.()
  }

  const handleCloseIconClick = () => {
    if (dataChanged) {
      setIsConfirmationDialogActive(true)
    } else {
      onClose?.()
    }
  }

  useEffect(() => {
    const recordsChanged = hasRecordDataChanged()
    setDataChanged(recordsChanged)
  }, [records])

  return {
    records,
    setRecords,
    inputData,
    setInputData,
    isMutating,
    isSecondaryMutating,
    loading,
    error,
    QueryWithRetryComponent,
    enableEdit,
    recalcMessage,
    recalcMessageColor,
    setRecalcMessage,
    dataChanged,
    handleCancel,
    handlePrimaryActionWithValidation,
    handleSecondaryClick,
    handleSave,
    getPrimaryLabel,
    preparingAsset,
    handleDiscard,
    isConfirmationDialogActive,
    setIsConfirmationDialogActive,
    handleSaveExit,
    handleCloseIconClick,
    riskNote,
    setRiskNote,
    displayingNotes,
    assetType
  }
}
