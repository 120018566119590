import React from 'react'
import { Button } from 'flowbite-react'
import * as msal from 'msal'
import { type LoginButtonProps } from '../../utility/types'

const LoginButton: React.FC<LoginButtonProps> = ({ msalInstance, userEmail, sessionId }) => {
  const loginWithHint = async () => {
    const silentRequest = {
      scopes: ['User.Read', 'Mail.Read'],
      loginHint: userEmail
    }
    try {
      await msalInstance.ssoSilent(silentRequest)
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        await msalInstance.loginPopup(silentRequest)
      } else {
        console.log('Error:', err)
      }
    }
  }

  const loginWithSessionId = async () => {
    const request = {
      scopes: ['user.read'],
      sid: sessionId
    }
    try {
      await msalInstance.ssoSilent(request)
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        await msalInstance.loginPopup(request)
      } else {
        console.log('Error:', err)
      }
    }
  }

  const loginNormally = async () => {
    try {
      await msalInstance.loginPopup()
    } catch (err) {
      console.log('Error:', err)
    }
  }

  const handleLoginClick = () => {
    if (userEmail) {
      loginWithHint()
    } else if (sessionId) {
      loginWithSessionId()
    } else {
      loginNormally()
    }
  }

  return (
        <div className="absolute top-0 right-0 p-4">
            <Button outline={true} onClick={handleLoginClick}>
                Login
            </Button>
        </div>
  )
}

export default LoginButton
