import type { InputAssetData, RawAssetData, RawBondData, RawEqtyData } from '../../../utility/types'

export const transformAssetData = (data: Array<RawAssetData & RawBondData & RawEqtyData>, testDetails: boolean) => {
  return data.flatMap((asset) => {
    const commonFields = {
      UniqueBatchId: asset.UniqueBatchId,
      BuyOrSell: asset.BuyOrSell,
      Currency: asset.Currency,
      Price: asset.Price,
      Isin: asset.Isin,
      Name: asset.Name,
      Quantity: asset.Quantity
    }

    const testsFields = {
      'SecurityTypeCheck*': asset.SecurityTypeCheck,
      'NotionalExposure*': asset.NotionalExposure,
      '20DAvgVol8*': asset.AvgVol20D,
      'SinglePos>15%(cpty)*': asset.SinglePosAgainstCpty,
      'SinglePos>15%(firm)*': asset.SinglePosAgainstCapMarketFirm,
      'Liquidity*': asset.Liquidity,
      'OutstandingShares*': asset.OutstandingShares,
      'MarketCap<$1Bn*': asset.MarketCapLessThanB,
      Overall: asset.Overall
    }

    const ratingFields = {
      Fitch: asset.Fitch,
      Moody: asset.Moody,
      SAndP: asset.SAndP
    }

    if (asset.AssetType === 'EQTY') {
      const equityFields = {
        ...(testDetails ? {} : { PrimaryExchange: asset.PrimaryExchange, CreatedDate: asset.CreatedDate }),
        Ticker: asset.Ticker,
        SecurityType: asset.SecurityType,
        Notional: asset.Notional
      }

      return {
        ...commonFields,
        ...equityFields,
        ...(testDetails ? testsFields : { Overall: asset.Overall })
      }
    } else if (asset.AssetType === 'BOND') {
      const bondFields = {
        ...(testDetails ? { CreatedDate: asset.CreatedDate } : {}),
        CountryRegion: asset.CountryRegion,
        MarketValue: asset.MarketValue,
        MaturityDate: asset.MaturityDate
      }

      return {
        ...commonFields,
        ...bondFields,
        ...(testDetails ? { ...ratingFields, ...testsFields } : { Overall: asset.Overall })
      }
    }

    return []
  })
}

export const formInputSessionData = (data: Array<RawAssetData & RawBondData & RawEqtyData & InputAssetData>) => {
  if (data.length > 0) {
    const firstRecord = data[0]
    return {
      Counterparty: firstRecord.Counterparty ?? '',
      TradeDate: firstRecord.TradeDate ?? '',
      EffectiveDate: firstRecord.EffectiveDate ?? '',
      TrsFinancingRate: firstRecord.TrsFinancingRate ?? '',
      ScheduledMaturityDate: firstRecord.ScheduledMaturityDate ?? '',
      FinalSettlementDate: firstRecord.FinalSettlementDate ?? '',
      LiquidityCptyTtl: firstRecord.LiquidityCptyTotal ?? '',
      ProjectedInitialMargin: firstRecord.ProjectedInitialMargin ?? '',
      MainAccountAllocation: firstRecord.MainAccountAllocation ?? ''
    }
  }
  return {
    Counterparty: '',
    TradeDate: '',
    EffectiveDate: '',
    TrsFinancingRate: '',
    ScheduledMaturityDate: '',
    FinalSettlementDate: '',
    LiquidityCptyTtl: '',
    ProjectedInitialMargin: '',
    MainAccountAllocation: ''
  }
}
