import React, { type FC } from 'react'
import RecordTable from '../../components/records-table'
import type { CombinedRecordType } from '../../utility/types'
import { TRADER_INPUT_FIELDS_MAP } from '../../utility/constant'
import InputBox from '../../components/inputbox'
import { type AssetBucketTabProps } from './utils/types'
import { formInputSessionData, transformAssetData } from './utils/formatting'

const AssetBucketTab: FC<AssetBucketTabProps> = ({
  fundingRequestADCId,
  assetDetailsData,
  assetType
}) => {
  const transformedData = transformAssetData(assetDetailsData, false)
  const inputFieldData = formInputSessionData(assetDetailsData)

  return (
    <div className="overflow-auto max-h-[70vh]">
      <InputBox
        fields={TRADER_INPUT_FIELDS_MAP(assetType)}
        inputData={inputFieldData}
      />
      <div className="flex flex-col overflow-auto">
        <div className={fundingRequestADCId ? 'px-4' : 'p-4 pt-0'}>
          <RecordTable<CombinedRecordType>
            records={transformedData}
            showExpandIcon={false}
          />
        </div>
      </div>
    </div>
  )
}

export default AssetBucketTab
