import React from 'react'
import AvatarProfile from '../../components/avatar'
import MultiTabFundingRequest from '../Ops/multi-tabs-funding-request'

const HeadPcCappage = () => {
  return (
        <div className='w-screen'>
            <AvatarProfile />
            <MultiTabFundingRequest status='PROG-COMPL-APPROVED' stage='Final' />
        </div>
  )
}

export default HeadPcCappage
