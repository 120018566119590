import { type FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import LiquidDashboard from './pages/Liquid/Dashboard'
import OpsDashboard from './pages/Ops/Dashboard'
import ManagmentOpsDashboard from './pages/Ops-management/ManagementDash'
import RiskApprovalPage from './pages/Risk-Officer'
// import BlotterUploader from './pages/File-uploading'
import CapPcpage from './pages/Cap-pc/PCCapDashboard'
import FundingRequstBoard from './pages/Funding-request'
import { type RouteInfo, type AppProps } from './utility/types'
import CapTraderPage from './pages/Cap-Trader/'
import HeadPcCappage from './pages/Cap-pc/HeadPCCapDashboard'

const commonRoutes: RouteInfo[] = [
  { path: '/dashboard', element: <FundingRequstBoard /> }
]

const routesForRoles: Record<string, RouteInfo[]> = {
  'LiquidMarket.Trader': [
    { path: '/liquid-cps', element: <LiquidDashboard /> }
  ],
  'Treasury.Trader': [
    { path: '/treasury-trader', element: <LiquidDashboard /> }
  ],
  'ProgramCompliance.Approver': [
    { path: '/program-compliance', element: <OpsDashboard /> },
    { path: '/cap-program-compliance-view', element: <CapPcpage /> }
  ],
  'ProgramCompliance.FinalApprover': [
    { path: '/management-program-compliance', element: <ManagmentOpsDashboard /> },
    { path: '/cap-management-program-compliance', element: <HeadPcCappage /> }
  ],
  'Capital.Markets.Risk.Officer': [
    { path: '/risk-review', element: <RiskApprovalPage /> }
  ],
  'CapitalMarket.Trader': [
    { path: '/cap-market-trader-view', element: <CapTraderPage /> }
  ],
  Administrator: [
    { path: '/treasury-trader', element: <LiquidDashboard /> },
    { path: '/program-compliance', element: <OpsDashboard /> },
    { path: '/management-program-compliance', element: <ManagmentOpsDashboard /> },
    // { path: '/realloc-fr-creation', element: <BlotterUploader /> },
    { path: '/cap-market-trader-view', element: <CapTraderPage /> },
    { path: '/cap-management-program-compliance', element: <HeadPcCappage /> },
    { path: '/risk-review', element: <RiskApprovalPage /> },
    { path: '/cap-program-compliance-view', element: <CapPcpage /> }
  ],
  RoleUndefined: []
}

const AppRoutes: FC<AppProps> = ({ userRole }) => {
  return (
    <Routes>
      {userRole !== 'RoleUndefined' && commonRoutes.map(routeInfo =>
        <Route key={routeInfo.path} {...routeInfo} />
      )}
      {userRole && routesForRoles[userRole].map(routeInfo =>
          <Route key={routeInfo.path} {...routeInfo} />
      )}
    </Routes>
  )
}

export default AppRoutes
