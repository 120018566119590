import React, { type FC, useEffect } from 'react'
import type { MessageProps } from '../../utility/types'

const Message: FC<MessageProps> = ({ message, color = 'red', clearMessage, className = '' }) => {
  useEffect(() => {
    if (message && color !== 'red') {
      const timer = setTimeout(clearMessage, 3000)
      return () => { clearTimeout(timer) }
    }
  }, [message, clearMessage, color])

  const handleClick = () => {
    if (color === 'red') {
      navigator.clipboard.writeText(message).then(() => {
        clearMessage()
      })
    }
  }

  const baseClasses = 'transition-opacity duration-300 ease-in-out inline-block'
  const colorClasses = {
    red: 'text-red-500 bg-red-100',
    green: 'text-green-500 bg-green-100',
    yellow: 'text-yellow-500 bg-yellow-100'
  }

  const combinedClasses = `${baseClasses} ${colorClasses[color]} ${className ?? ''}`.trim()

  return (
    <span className={combinedClasses} onClick={handleClick}>
      {message}
     </span>
  )
}

export default Message
