import React, { useState, type FC, useEffect } from 'react'
import { GET_ASSET_SUM_BY_DATE, GET_AVALIBLE_ASSETS_DATE } from '../graphql/queries'
import RenderAssetSection from './asset-section'
import refreshIcon from '../../utility/img/reload.png'
import Message from '../message'
import { type AssetsListProps } from '../../utility/types'
import { useQueryWithRetry } from '../../hooks/useQueryWithRetry'

const AssetsList: FC<AssetsListProps> = ({ type }) => {
  const today = new Date().toISOString().split('T')[0]
  const [selectedDate, setSelectedDate] = useState<string>(today)
  const [message, setMessage] = useState<string | null>(null)
  const [messageColor, setMessageColor] = useState<'red' | 'green' | 'yellow'>('green')

  const { loading: datesLoading, data: datesData, error: datesError, QueryWithRetryComponent: AvailableAssetsRetryComponent } = useQueryWithRetry(
    {
      query: GET_AVALIBLE_ASSETS_DATE,
      options: {
        variables: { process: 'CapMarketAsset' }
      }
    })
  const { loading, error, data, refetch, QueryWithRetryComponent } = useQueryWithRetry(
    {
      query: GET_ASSET_SUM_BY_DATE,
      options: {
        variables: { startDate: selectedDate }
      }
    })

  const tradeDates: string[] = datesData?.getAvailabilityForAssets?.trade_dates.map((dateStr: string) => {
    const dateRegex = /(\d{4}), (\d{1,2}), (\d{1,2})/
    const match = dateStr.match(dateRegex)
    if (match) {
      const year = match[1]
      const month = match[2].padStart(2, '0')
      const day = match[3].padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      return formattedDate !== today ? formattedDate : null
    }
    return null
  }).filter((date: string | null) => date !== null)

  const handleRefresh = () => {
    refetch({ startDate: selectedDate })
  }

  useEffect(() => {
    if (datesError) {
      setMessage('Error fetching trade dates')
      setMessageColor('red')
    } else {
      setMessage(null)
    }
  }, [datesError])

  useEffect(() => {
    refetch({ startDate: selectedDate })
  }, [selectedDate, refetch])

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.target.value)
  }

  if (loading || error) return <QueryWithRetryComponent />
  if (datesLoading || datesError) return <AvailableAssetsRetryComponent />

  return (
    <>
    <div className="flex justify-between items-center mt-4">
      <div className="flex items-center">
        <div className='ml-2 mr-2'>Available Dates: </div>
        <select
          className="w-full max-w-xs rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={selectedDate}
          onChange={handleDateChange}
        >
          <option value={today}>{today}</option>
          {tradeDates.map((date, idx) => (
            <option key={idx} value={date}>{date}</option>
          ))}
        </select>
        {message && (
          <Message message={message} color={messageColor} clearMessage={() => { setMessage(null) }} className="ml-4" />
        )}
      </div>
      <button onClick={handleRefresh} className="flex items-center justify-center p-2 mr-4 text-white rounded hover:bg-gray-100">
        <img src={refreshIcon} alt="Refresh" width={20} height={20} />
      </button>
    </div>
      <div className="p-4">
        <RenderAssetSection assetType="EQTY" data={data} refetch={refetch} setMessage={setMessage} setMessageColor={setMessageColor} type={type}/>
        <hr className="my-4 border-t border-gray-200" />
        <RenderAssetSection assetType="BOND" data={data} refetch={refetch} setMessage={setMessage} setMessageColor={setMessageColor} type={type}/>
      </div>
    </>
  )
}

export default AssetsList
