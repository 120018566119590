import React, { type FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import SidebarMenu from './components/sidebar'
import { withMsal, type IMsalContext } from '@azure/msal-react'
import AppRoutes from './routes'
import LoginButton from './components/loginbutton'
import { getUserRoleMenu, getMenuItemsForRole } from './utility/userRoleMenuUtils'
import UserRoleContext from './contexts/UserRoleContext'
import GenericMessagePage from './pages/GenericPages/GenericMessagePage'
import { RoleNotAssignedMsg } from './utility/utilMessages'

interface AppProps {
  msalContext: IMsalContext
}

const App: FC<AppProps> = ({ msalContext }) => {
  const isAuthenticated = msalContext.accounts.length > 0
  const msalInstance = msalContext.instance
  const activeAccount = msalContext.instance.getActiveAccount()

  const userEmail = (activeAccount?.idTokenClaims?.preferred_username ?? activeAccount?.idTokenClaims?.email) as string | undefined
  const sessionId = activeAccount?.idTokenClaims?.sid
  const roles = activeAccount?.idTokenClaims?.roles ?? []
  const userRoleMenu = getUserRoleMenu(roles)
  const isUserRoleDefined = userRoleMenu !== 'RoleUndefined'

  return (
    <UserRoleContext.Provider value={userRoleMenu}>
      <Router>
        <div className="flex max-h-fit">
          <SidebarMenu menuItems={getMenuItemsForRole(userRoleMenu)} auth={isAuthenticated} msalInstance={msalInstance} />
          <AppRoutes userRole={userRoleMenu} />
          {!isAuthenticated && <LoginButton msalInstance={msalInstance} userEmail={userEmail} sessionId={sessionId} />}
          {isAuthenticated && !isUserRoleDefined && <GenericMessagePage msgFormatting={RoleNotAssignedMsg.msgFormatting} msgText={RoleNotAssignedMsg.msgText} />}
        </div>
      </Router>
    </UserRoleContext.Provider>
  )
}

export default withMsal(App)
