export const isSameDay = (d1: Date, d2: Date) => {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
}

export const isNumericString = (value: unknown): boolean => {
  if (typeof value === 'number') {
    return true
  }
  if (typeof value === 'string') {
    const cleanedValue = value.replace(/[$,]/g, '')
    const num = parseFloat(cleanedValue)
    return (!isNaN(num) && isFinite(num))
  }
  return false
}
