import React, { type FC, useState, type ChangeEvent } from 'react'
import { formatInputToDollar } from '../../utility/formatting'
import { type DropdownOption, type TextInputProps } from '../../utility/types'

const TextInput: FC<TextInputProps> = ({
  type,
  className,
  inputClassName = '',
  placeholder,
  prefix,
  suffix,
  value: propValue,
  setValue,
  format = null,
  dropdown1 = null,
  dropdown2 = null
}) => {
  const [inputValue, setInputValue] = useState<string>(propValue)

  const getConsolidatedValue = (currentValue: string) => {
    let consolidatedValue = ''

    if (dropdown1) {
      consolidatedValue += dropdown1.selectedOption + '-'
    }

    if (dropdown2) {
      consolidatedValue += dropdown2.selectedOption + '-'
    }

    consolidatedValue += currentValue

    return consolidatedValue
  }

  const updateValue = (value: string) => {
    setInputValue(value)
    setValue(getConsolidatedValue(value))
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateValue(e.target.value)
  }

  const handleBlur = () => {
    if (format === 'dollar') {
      const formattedValue = formatInputToDollar(inputValue)
      updateValue(formattedValue)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  const handleDropdownChange = (selectedValue: string, selectedCode: number, dropdownType: 'dropdown1' | 'dropdown2') => {
    let updatedDropdownValue = ''

    if (dropdownType === 'dropdown1' && dropdown1) {
      dropdown1.setSelectedOption(selectedValue)
      dropdown1.setSelectedCode(selectedCode)
      updatedDropdownValue = selectedValue + '-' + (dropdown2 ? dropdown2.selectedOption : '') + '-' + inputValue
    } else if (dropdownType === 'dropdown2' && dropdown2) {
      dropdown2.setSelectedOption(selectedValue)
      dropdown2.setSelectedCode(selectedCode)
      updatedDropdownValue = (dropdown1 ? dropdown1.selectedOption : '') + '-' + selectedValue + '-' + inputValue
    }

    if (updatedDropdownValue) {
      setValue(updatedDropdownValue)
    }
  }

  return (
    <div className={className}>
      {prefix && <span className="font-bold">{prefix}</span>}
      {dropdown1 && (
        <>
          <select
            value={dropdown1.selectedOption}
            onChange={(e) => {
              const selected = dropdown1.options.find(opt => opt.label === e.target.value)
              if (selected) {
                handleDropdownChange(selected.label, selected.code, 'dropdown1')
              }
            }}
            className={`rounded-md border-gray-300 focus:outline-none focus:ring-2 ${inputClassName}`}
          >
            {dropdown1.options.map((opt: DropdownOption) => (
              <option key={opt.code} value={opt.label}>
                {opt.label}
              </option>
            ))}
          </select>
          <span>-</span>
        </>
      )}

      {dropdown2 && (
        <>
          <select
            value={dropdown2.selectedOption}
            onChange={(e) => {
              const selected = dropdown2.options.find(opt => opt.label === e.target.value)
              if (selected) {
                handleDropdownChange(selected.label, selected.code, 'dropdown2')
              }
            }}
            className={`rounded-md border-gray-300 focus:outline-none focus:ring-2 ${inputClassName}`}
          >
            {dropdown2.options.map((opt: DropdownOption) => (
              <option key={opt.code} value={opt.label}>
                {opt.label}
              </option>
            ))}
          </select>
          <span>-</span>
        </>
      )}
      <input
        type={type}
        className={`rounded-md w-32 border-gray-300 focus:outline-none focus:ring-2 ${inputClassName}`}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <span className="font-bold">{suffix}</span>
    </div>
  )
}

export default TextInput
