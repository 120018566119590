import React, { useEffect, useState } from 'react'
import { type GenericDropdownProps } from '../../utility/types'

const GenericDropdown: React.FC<GenericDropdownProps> = ({
  options,
  selectedValue = null,
  onChange,
  disabled,
  displayMsg,
  className = '',
  fetchOptions = null
}) => {
  const [selectedDropValue, setSelectedDropValue] = useState<string>(selectedValue ?? '')
  const updateValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tempValue = e.target.value
    onChange?.(tempValue)
    setSelectedDropValue(tempValue)
  }

  const handleFocus = () => {
    if (fetchOptions) {
      fetchOptions()
    }
  }

  useEffect(() => {
    if (selectedValue) {
      const newSelectedValue = options?.includes(selectedValue) ? selectedValue : ''
      setSelectedDropValue(newSelectedValue)
    }
  }, [options, selectedValue, onChange])

  return (
    <div className="relative">
      <select
        className={`block appearance-none w-full rounded-md text-sm border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${className}`}
        value={selectedDropValue}
        onChange={updateValue}
        onFocus={handleFocus}
        disabled={disabled}
      >
        <option value="" disabled>{displayMsg}</option>
        {options?.map((option, idx) => (
          <option key={idx} value={option}>{option}</option>
        ))}
      </select>
    </div>
  )
}

export default GenericDropdown
