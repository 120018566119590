import React from 'react'

interface StatusIndicatorProps {
  status: boolean
  label: string
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, label }) => {
  return (
        <div className="flex items-center gap-1.5">
            <span className={`h-2 w-2 rounded-full ${status ? 'bg-green-500' : 'bg-red-500'}`}></span>
            <p className="font-semibold">{label}</p>
        </div>
  )
}

export default StatusIndicator
