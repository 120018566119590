import React, { type FC } from 'react'
import { type CardBodyProps } from '../utils/types'
import { formatEmailToName } from '../../../utility/formatting'

const FundingRequestCard: FC<CardBodyProps> = ({ data, onClick, status }) => {
  return (
    <div onClick={onClick} className="w-full p-4 whitespace-nowrap overflow-hidden overflow-ellipsis">
      <div>
        <h2 className='flex'><p className='font-semibold pr-1'>FR ID:</p>{data.fundingRequestCode}</h2>
        <h2 className='flex'><p className='font-semibold pr-1'>CP Issuance Ids:</p>{data.cpIssuancesId}</h2>
        <h2 className='flex'><p className='font-semibold pr-1'>Submitted By:</p>{formatEmailToName(data.submittedBy)}</h2>
        <h2 className='flex'><p className='font-semibold pr-1'>Submitted Time:</p>{data.timeSubmitted}</h2>
        <h2 className='flex'><p className='font-semibold pr-1'>Status:</p>{status ?? data.status}</h2>
        </div>
    </div>
  )
}

export default FundingRequestCard
