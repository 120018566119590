import React, { type FC, useEffect, useState, type MouseEvent, useCallback } from 'react'
import { CP_SUBMIT_WITH_ADC_ID_CREATE } from '../../../components/graphql/mutations'
import MiniNotepad from '../../../components/notepad'
import { useMutation } from '@apollo/client'
import TextInput from '../../../components/field-input'
import { type PopupProps, type GraphData } from '../../../utility/types'
import { useGraphData } from '../../../useGraphData'
import CPInfo from '../../../components/cp-info'
import {
  parseCurrencyString,
  formatInputToDollar,
  formatKey,
  formatDateToMMddyy,
  stringToFloat,
  parseDollarToNumber
} from '../../../utility/formatting'
import ActionButton from '../../../components/action-button'

const Popup: FC<PopupProps> = ({ show, mode, onClose, data, setMessage, setMessageColor, refetch }) => {
  const formattedDate = formatDateToMMddyy(new Date())
  const [frValue, setFrValue] = useState(`${formattedDate}-`)
  const [noteText, setNoteText] = useState('')
  const [pe, setPe] = useState(false)
  const [ctaText, setCtaText] = useState('-bps')
  const [selectedPrefix1, setSelectedPrefix1] = useState('')
  const [selectedPrefix2, setSelectedPrefix2] = useState('')
  const [leftCptyCode, setLeftCptyCode] = useState(0)
  const [rightCptyCode, setRightCptyCode] = useState(0)
  const [graphData, setGraphData] = useState<GraphData | null>(null)
  const [loadingGraphData, setLoadingGraphData] = useState(true)
  const [createFRForApproval, { error, loading }] = useMutation(CP_SUBMIT_WITH_ADC_ID_CREATE)

  const [loadedGraphData] = useGraphData()

  useEffect(() => {
    if (loadedGraphData?.mail) {
      setGraphData(loadedGraphData)
      setLoadingGraphData(false)
    }
  }, [loadedGraphData])

  const extractCpPairDetails = (cpPair: string) => {
    const [label, code] = cpPair.split('*')
    return {
      label,
      code: parseInt(code, 10)
    }
  }

  const dc = Array.isArray(data)
    ? data.reduce((acc, curr) => acc + parseCurrencyString(curr.aDC), 0)
    : parseCurrencyString(data.aDC)
  const designatedCash = formatInputToDollar(dc)
  const [designatedCashV, setDesignatedCashV] = useState(designatedCash)

  const cpPair = Array.isArray(data) ? data[0].cpPair : data.cpPair

  const cpPairs = cpPair.map(cp => ({
    ...cp,
    cpLeft: extractCpPairDetails(cp.cpLeft),
    cpRight: cp.cpRight.map(r => extractCpPairDetails(r))
  }))

  const dropdown1Props = {
    options: cpPairs.map(cp => cp.cpLeft),
    selectedOption: selectedPrefix1,
    selectedCode: leftCptyCode,
    setSelectedOption: setSelectedPrefix1,
    setSelectedCode: setLeftCptyCode
  }

  const selectedCode = dropdown1Props.selectedCode
  const matchingPair = cpPairs.find(e => e.cpLeft.code === selectedCode) ?? { cpRight: [] }

  const dropdown2Props = {
    options: matchingPair.cpRight,
    selectedOption: selectedPrefix2,
    selectedCode: rightCptyCode,
    setSelectedOption: setSelectedPrefix2,
    setSelectedCode: setRightCptyCode
  }

  useEffect(() => {
    if (cpPairs.length > 0) {
      setSelectedPrefix1(cpPairs[0].cpLeft.label)
      setLeftCptyCode(cpPairs[0].cpLeft.code)
      if (cpPairs[0].cpRight.length > 0) {
        setSelectedPrefix2(cpPairs[0].cpRight[0].label)
        setRightCptyCode(cpPairs[0].cpRight[0].code)
      }
    }
  }, [cpPair])

  useEffect(() => {
    if (error) {
      setMessage('An error occurred while updating status')
      setMessageColor('red')
    }
  }, [error, setMessage, setMessageColor])

  const stopPropagation = useCallback((event: MouseEvent) => { event.stopPropagation() }, [])

  const toggleCTA = useCallback(() => {
    if (ctaText === '-bps') {
      setCtaText('+bps')
      setPe(true)
      const newDesignatedCash = Array.isArray(data)
        ? data.reduce((acc, curr) => acc + parseCurrencyString(curr.cPDiscountAmount), 0)
        : parseCurrencyString(data.cPDiscountAmount)
      setDesignatedCashV(formatInputToDollar(newDesignatedCash))
    } else {
      setCtaText('-bps')
      setPe(false)
      setDesignatedCashV(designatedCash)
    }
  }, [ctaText, data])

  const handleAccept = useCallback(async () => {
    if (!graphData) {
      setMessage('User data is not available')
      setMessageColor('red')
      return
    }

    const cpIdList = Array.isArray(data)
      ? data.map(({ cPIssuanceId }) => cPIssuanceId).join(',')
      : data.cPIssuanceId.toString()

    const { data: responseData } = await createFRForApproval({
      variables: {
        cp_id_list: cpIdList,
        created_by: graphData?.mail,
        fr_code: `FR-${frValue}`,
        note: noteText,
        designated_cash: stringToFloat(designatedCashV),
        pe_override: pe,
        left_cpty_code: leftCptyCode,
        right_cpty_code: rightCptyCode
      }
    })

    const { status, error: responseError } = responseData.submitAndCreateADCFundingRequest

    setMessage(status ? 'Successfully created a FR submission' : responseError)
    setMessageColor(status ? 'green' : 'red')
    refetch()
    onClose()
  }, [graphData, data, frValue, noteText, designatedCashV, pe])

  const handleOnClose = useCallback(async () => {
    setFrValue(`${formattedDate}-`)
    refetch()
    onClose()
  }, [])

  const handleNumericInputFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = parseDollarToNumber(designatedCashV)
  }

  const handleNumericInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatInputToDollar(e.target.value)
    e.target.value = formattedValue
    const numericValue = parseDollarToNumber(e.target.value)
    handleInputChange(numericValue)
  }

  const handleInputChange = (numericValue: string) => {
    setDesignatedCashV(numericValue)
  }

  if (!show) return null

  return (
    <div onClick={handleOnClose} className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div onClick={stopPropagation} className="inline-block align-bottom
                                                  bg-white rounded-lg text-left
                                                  overflow-hidden shadow-xl transform transition-all
                                                  sm:my-8 sm:align-middle sm:max-w-2xl sm:w-auto">
              <div className="bg-white px-4 pt-5">
                  <div className="sm:flex sm:justify-between sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                          {mode === 'merge' ? 'Merge Cards' : 'Card Details'}
                        </h3>
                        <div className="mt-2">
                          {mode === 'merge'
                            ? <CPInfo data={Array.isArray(data) ? data : [data]} autoSelect setCpExpand={() => {}}/>
                            : (
                              <div className="text-sm">
                                {Object.entries(!Array.isArray(data) ? data : {}).slice(1, -1).map(([key, value], i) => (
                                  <div className="flex" key={i}>
                                    <strong className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">
                                    {formatKey(key)}:
                                    </strong>
                                    <div className="text-sm text-gray-500 ml-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
                                      {value}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              )
                            }
                          <div className='flex-grow w-full items-center mt-2 space-x-1'>
                            <span className='font-bold'>Designated Cash (Sug.) $</span>
                              <input
                                type="text"
                                className="text-right w-32 rounded-md border-gray-300 shadow-sm focus:ring-gray-100 focus:border-gray-300"
                                defaultValue={designatedCash}
                                value={designatedCashV}
                                onFocus={(e) => { handleNumericInputFocus(e) }}
                                onBlur={(e) => { handleNumericInputBlur(e) }}
                              />
                            <button
                              onClick={toggleCTA}
                              className="inline-flex justify-center items-center w-16 rounded-lg border
                                        border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium
                                        text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2
                                        focus:ring-offset-1 focus:ring-gray-200 sm:text-sm"
                            >
                              {ctaText}
                            </button>
                          </div>
                          <TextInput
                            type="text"
                            prefix="FR-"
                            className="flex items-center mt-2"
                            inputClassName="flex-grow focus:ring-gray-100 focus:border-gray-300"
                            placeholder={`${formattedDate}-`}
                            value={frValue}
                            setValue={setFrValue}
                            dropdown1={dropdown1Props}
                            dropdown2={dropdown2Props}
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-right sm:items-center">
                          <MiniNotepad noteText={noteText} setNoteText={setNoteText} autoExpand/>
                        </div>
                      </div>
                  </div>
              </div>
              <ActionButton
                  primaryLabel={'Generate FR'}
                  secondaryLabel="Cancel"
                  primaryAction={handleAccept}
                  secondaryAction={handleOnClose}
                  primaryLoading={loading}
                  primaryDisabled={loadingGraphData}
              />
          </div>
      </div>
  </div>
  )
}

export default React.memo(Popup)
