import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus, InteractionRequiredAuthError, type AccountInfo } from '@azure/msal-browser'
import { callMsGraph } from './utility/msGraphApiCall'
import { loginRequest } from './authConfig'
import { type GraphData } from './utility/types'

export const useGraphData = (): [GraphData, boolean] => {
  const { instance, inProgress } = useMsal()
  const initialGraphData: GraphData = {
    mail: '',
    displayName: '',
    jobTitle: '',
    loading: true
  }

  // Default to an "empty" state.
  const [graphData, setGraphData] = useState<GraphData>(initialGraphData)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchGraphData = async () => {
      setLoading(true)
      try {
        const response = await callMsGraph()
        setGraphData({
          ...response,
          loading: false
        })
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount() as AccountInfo
          })
        }
      } finally {
        setLoading(false)
      }
    }

    if (inProgress === InteractionStatus.None && isEmpty(graphData)) {
      fetchGraphData()
    }
  }, [inProgress, graphData, instance])

  return [graphData, loading]
}

// Helper function to check if the data is in its initial state
function isEmpty (data: GraphData): boolean {
  return data.mail === '' && data.displayName === '' && data.jobTitle === ''
}
