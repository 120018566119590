import React, { useState, useEffect } from 'react'
import { GET_FUNDING_REQUEST_BY_STATUS } from '../../../components/graphql/queries'
import { type FundingRequestSum } from '../../Ops/utils/types'
import Message from '../../../components/message'
import FundingRequestGroup from './FundingRequestGroup'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'

const GroupedFundingRequest = () => {
  const desiredStatusOrder = ['REJECT', 'NEW', 'INIT_APPROVE', 'APPROVE']

  const [message, setMessage] = useState<string | null>(null)
  const [messageColor, setMessageColor] = useState<'red' | 'green' | 'yellow'>('green')

  const [groupedData, setGroupedData] = useState<Record<string, FundingRequestSum[]>>({})

  const { data, refetch, QueryWithRetryComponent, loading, error } = useQueryWithRetry({
    query: GET_FUNDING_REQUEST_BY_STATUS,
    options: {
      variables: { status: desiredStatusOrder.join(','), from_date: '2024-01-01' }
    }
  })

  useEffect(() => {
    if (data?.getFundingRequestByStatus) {
      const grouped = groupByStatus(data.getFundingRequestByStatus)
      setGroupedData(grouped)
    }
  }, [data])

  if (loading || error) return <QueryWithRetryComponent />

  const groupByStatus = (list: FundingRequestSum[]): Record<string, FundingRequestSum[]> => {
    return list.reduce<Record<string, FundingRequestSum[]>>((acc, item) => {
      if (!acc[item.status]) {
        acc[item.status] = []
      }
      acc[item.status].unshift(item)
      return acc
    }, {})
  }

  return (
    <>
      <div className="relative h-5">
        {message && <Message message={message} color={messageColor} clearMessage={() => { setMessage('') }} />}
      </div>
      <div className="p-4">
        {desiredStatusOrder.map(status => (
          groupedData[status] && (
            <FundingRequestGroup
              key={status}
              status={status}
              fundingRequests={groupedData[status]}
              refetch={refetch}
              setMessage={setMessage}
              setMessageColor={setMessageColor}
            />
          )
        ))}
      </div>
    </>
  )
}

export default GroupedFundingRequest
