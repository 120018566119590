import React from 'react'
import AvatarProfile from '../../components/avatar'
import GroupedFundingRequest from './grouped-fr'

const FundingRequstBoard = () => {
  return (
    <div className='w-screen'>
        <AvatarProfile />
        <GroupedFundingRequest />
    </div>
  )
}

export default FundingRequstBoard
