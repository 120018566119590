import { type FundingRecord, type CPDebtIssuanceRecord, type LoanFromLenderRecord } from './types'

export const extractCPDebtIssuanceRecord = (record: FundingRecord): CPDebtIssuanceRecord => {
  return {
    issuer: record.issuer,
    fundingDate: record.fundingDate,
    maturityDate: record.maturityDate,
    tenorDays: record.tenorDays,
    faceAmount: record.faceAmount,
    proceeds: record.proceeds,
    discountRate: record.discountRateTotal,
    discountAmount: record.discountAmount,
    moodysRating: record.moodysRating,
    sAndPRating: record.sAndPRating
  }
}

export const extractLoanFromLenderRecord = (record: FundingRecord): LoanFromLenderRecord => {
  return {
    lender: record.issuer,
    borrower: record.companyName,
    fundingDate: record.fundingDate,
    maturityDate: record.maturityDate,
    tenorDays: record.tenorDays,
    principal: record.principal,
    interestRate: record.interestRate,
    interestAmount: record.interestAmount,
    amountDueAtMaturity: record.amountDueAtMaturity
  }
}
