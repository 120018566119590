import React from 'react'
import closeIcon from '../../utility/img/cancel.png'
import ActionButton from '../action-button'
import type { ConfirmationModalProps } from './types'

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  onClose = onCancel,
  question = 'Are you sure you want to proceed this action?',
  title = 'Confirm Action',
  confirmText = 'Proceed',
  cancelText = 'Cancel',
  confirmButtonColor,
  cancelButtonColor,
  primaryLoading = false,
  secondaryLoading = false
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex bg-black bg-opacity-50">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
        <div className="absolute top-0 left-0 p-4">
          <button onClick={async () => { await onCancel() }} className="transition duration-150 ease-in-out rounded-full">
            <img src={closeIcon} alt='Close' width={24} height={24}/>
          </button>
        </div>
        <div>
          <h1 className="text-xl font-semibold mt-4">{title}</h1>
          <p className="mt-2 mb-2">{question}</p>
        </div>
        <div>
          <ActionButton
            primaryLabel={confirmText}
            secondaryLabel={cancelText}
            primaryAction={onConfirm}
            secondaryAction={onClose}
            primaryButtonColor={confirmButtonColor}
            secondaryButtonColor={cancelButtonColor}
            primaryLoading={primaryLoading}
            secondaryLoading={secondaryLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
