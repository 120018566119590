import React, { type FC } from 'react'
import { type InfoProps } from '../utils/types'
import { formatKey } from '../../../utility/formatting'

const Info: FC<InfoProps> = ({ data, title }) => {
  return (
    <div className='space-x-1 align-items-center'>
      <h4 className='font-bold'>{title}</h4>
      {data?.map((record, index) => (
          <div key={index}>
            {record &&
              Object.entries(record)
                .filter(([key, value]) => !key.includes('_') && value !== null && value !== undefined)
                .map(([key, value]) => {
                  return (<div className="flex" key={key}>
                  <strong className="font-semibold text-sm">{formatKey(key)}:</strong>
                  <p className="text-sm text-gray-500 ml-2">{value}</p>
                </div>)
                })}
          </div>
      ))}
    </div>
  )
}

export default Info
