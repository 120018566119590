import { BOND_EDITABLE_FIELDS, EQUITY_EDITABLE_FIELDS } from './constant'
import type { InputAssetData, RawAssetData, RawBondData, RawEqtyData } from '../../../utility/types'

export const transformAssetData = (data: Array<RawAssetData & RawBondData & RawEqtyData>) => {
  return data.flatMap((asset) => {
    const commonFields = {
      UniqueBatchId: asset.UniqueBatchId,
      BuyOrSell: asset.BuyOrSell,
      Currency: asset.Currency,
      Isin: asset.Isin,
      Name: asset.Name,
      Quantity: asset.Quantity,
      Price: asset.Price
    }
    const testsFields = {
      'SecurityTypeCheck*': asset.SecurityTypeCheck,
      'NotionalExposure*': asset.NotionalExposure,
      '20DAvgVol8*': asset.AvgVol20D,
      'SinglePos>15%(cpty)*': asset.SinglePosAgainstCpty,
      'SinglePos>15%(firm)*': asset.SinglePosAgainstCapMarketFirm,
      'Liquidity*': asset.Liquidity,
      'OutstandingShares*': asset.OutstandingShares,
      'MarketCap<$1Bn*': asset.MarketCapLessThanB,
      Overall: asset.Overall
    }

    if (asset.AssetType === 'EQTY') {
      return {
        ...commonFields,
        Ticker: asset.Ticker,
        PrimaryExchange: asset.PrimaryExchange,
        SecurityType: asset.SecurityType,
        Notional: asset.Notional,
        ...testsFields
      } satisfies RawEqtyData & RawAssetData
    } else if (asset.AssetType === 'BOND') {
      return {
        ...commonFields,
        TraderPrice: asset.TraderPrice,
        MarketValue: asset.MarketValue,
        MaturityDate: asset.MaturityDate,
        Fitch: asset.Fitch,
        Moody: asset.Moody,
        SAndP: asset.SAndP,
        ...testsFields
      } satisfies RawBondData & RawAssetData
    }
    return []
  })
}

export const formInputSessionData = (data: Array<RawAssetData & RawBondData & RawEqtyData & InputAssetData>) => {
  if (data.length > 0) {
    const firstRecord = data[0]
    let sessionData: InputAssetData = {
      Counterparty: firstRecord.Counterparty ?? '',
      TradeDate: firstRecord.TradeDate ?? '',
      EffectiveDate: firstRecord.EffectiveDate ?? '',
      TrsFinancingRate: firstRecord.TrsFinancingRate ?? '',
      ScheduledMaturityDate: firstRecord.ScheduledMaturityDate ?? '',
      FinalSettlementDate: firstRecord.FinalSettlementDate ?? '',
      LiquidityCptyTtl: firstRecord.LiquidityCptyTotal ?? '',
      ProjectedInitialMargin: firstRecord.ProjectedInitialMargin ?? '',
      CheckTradeDate: firstRecord.CheckTradeDate ?? '',
      CheckEffectiveDate: firstRecord.CheckEffectiveDate ?? '',
      CheckScheduledMaturityDate: firstRecord.CheckScheduledMaturityDate ?? '',
      CheckFinalSettlementDate: firstRecord.CheckFinalSettlementDate ?? '',
      MainAccountAllocation: firstRecord.MainAccountAllocation ?? ''
    }

    if (firstRecord.AssetType === 'BOND') {
      sessionData = {
        ...sessionData,
        EarlyTerminationDate: firstRecord.EarlyTerminationDate ?? '',
        CheckEarlyTerminationDate: firstRecord.CheckEarlyTerminationDate ?? ''
      }
    }

    return sessionData
  }

  return {
    Counterparty: '',
    TradeDate: '',
    EffectiveDate: '',
    TrsFinancingRate: '',
    ScheduledMaturityDate: '',
    FinalSettlementDate: '',
    LiquidityCptyTtl: '',
    ProjectedInitialMargin: '',
    MainAccountAllocation: ''
  }
}

export function getEditableFieldsForAssetType (assetType: string): string[] {
  switch (assetType) {
    case 'BOND':
      return BOND_EDITABLE_FIELDS
    case 'EQTY':
      return EQUITY_EDITABLE_FIELDS
    default:
      return []
  }
}
