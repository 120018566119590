import React, { type FC, useState } from 'react'
import { type CPProps, type CPInfoProps } from '../../utility/types'
import { formatKey } from '../../utility/formatting'

const CPDetails: FC<{ cp: CPProps }> = ({ cp }) => (
  <div className="cp-details">
    {Object.entries(cp).slice(1, -1).map(([key, value], i) => {
      return (
        <div className="flex" key={i}>
          <strong className="font-semibold text-sm">{formatKey(key)}:</strong>
          <p className="text-sm text-gray-500 ml-2">{value}</p>
        </div>
      )
    })}
  </div>
)

const CPInfo: FC<CPInfoProps> = ({ data, autoSelect = false, setCpExpand }) => {
  const [selectedCP, setSelectedCP] = useState<CPProps | null>(
    autoSelect ? data?.[0] || null : null
  )

  const handleCPSelect = (cp: CPProps) => {
    if (selectedCP && selectedCP.cPIssuanceId === cp.cPIssuanceId) {
      setSelectedCP(null)
      setCpExpand?.(false)
    } else {
      setSelectedCP(cp)
      setCpExpand?.(true)
    }
  }

  return (
    <div className='space-x-1 align-items-center'>
      <div className="flex">
        <h4 className="p-1 font-bold">CP Information:</h4>
        <div className="flex space-x-4">
          {data?.map((cp, index) => (
            <div
              key={index}
              onClick={() => { handleCPSelect(cp) }}
              className={
                selectedCP && selectedCP.cPIssuanceId === cp.cPIssuanceId
                  ? 'bg-blue-200 p-1 rounded'
                  : 'p-1'}
            >
              {cp.cPIssuanceId}
            </div>
          ))}
        </div>
      </div>
      {selectedCP && <CPDetails cp={selectedCP} />}
    </div>
  )
}

export default CPInfo
