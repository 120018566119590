import React from 'react'
import AvatarProfile from '../../components/avatar'
import AssetList from '../../components/assets'

const CapTraderpage = () => {
  return (
        <div className='w-screen'>
            <AvatarProfile />
            <AssetList type='cap' />
        </div>
  )
}

export default CapTraderpage
