import React from 'react'
import FundingRequest from '../Ops/funding-request'
import AvatarProfile from '../../components/avatar'

const ManagmentOpsDashboard = () => {
  return (
    <div className='w-screen'>
        <AvatarProfile />
        <FundingRequest status='INIT_APPROVE' stage='Final'/>
    </div>
  )
}

export default ManagmentOpsDashboard
