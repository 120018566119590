import { type BlotterRecord } from '../../../pages/File-uploading/utils/types'
import { formatInputToDollar, parseCurrencyString, parseDollarToNumber } from '../../../utility/formatting'
import { type CombinedRecordType } from '../../../utility/types'

interface RecalcMVProps<T> {
  records: T[]
  updatedValue: Partial<CombinedRecordType>
  uniqueBatchId: number | null
  key: keyof (CombinedRecordType)
}

interface TotalResult {
  totalMV: number
  totalQuantity: number
}

export const recalculateMarketValue = <T extends CombinedRecordType | BlotterRecord>(
  {
    records,
    updatedValue,
    uniqueBatchId,
    key
  }: RecalcMVProps<T>): { value: string, valueType: 'MarketValue' | 'Notional', totalRecalc: TotalResult } | null => {
  const record = records.find(r => r.UniqueBatchId === uniqueBatchId)
  if (!record) return null

  const traderPrice = parseCurrencyString(updatedValue.TraderPrice?.toString() ?? record.TraderPrice?.toString() ?? '0')
  const quantity = parseCurrencyString(updatedValue.Quantity?.toString() ?? record.Quantity?.toString() ?? '0')
  const price = parseCurrencyString(record.Price as string) ?? '0'

  if (key === 'TraderPrice' || key === 'Quantity') {
    const effectivePrice = traderPrice > 0 ? traderPrice : price
    const newMarketValue = effectivePrice * quantity
    const marketValue = formatInputToDollar(newMarketValue, { prefix: '$' })

    const totalRecalc = calcTotalMVandQuantity({ records, uniqueBatchId, curVal: newMarketValue, curQuan: quantity })

    const valueType = 'MarketValue' in record && record.MarketValue !== undefined ? 'MarketValue' : 'Notional'
    return { value: marketValue, valueType, totalRecalc }
  }
  return null
}

export const calcTotalMVandQuantity = <T extends CombinedRecordType | BlotterRecord>(
  {
    records,
    uniqueBatchId,
    curVal,
    curQuan
  }: { records: T[], uniqueBatchId?: number | null, curVal?: number, curQuan?: number }): TotalResult => {
  let totalMV = 0
  let totalQuantity = 0

  records.forEach(record => {
    let marketValueNumber = 0
    let notionalNumber = 0

    if ('MarketValue' in record && record.MarketValue !== undefined && (typeof record.MarketValue === 'string' || typeof record.MarketValue === 'number')) {
      marketValueNumber = parseFloat(parseDollarToNumber(record.MarketValue))
    }
    if ('Notional' in record && record.Notional !== undefined && (typeof record.Notional === 'string' || typeof record.Notional === 'number')) {
      notionalNumber = parseFloat(parseDollarToNumber(record.Notional))
    }
    if (typeof record.Quantity === 'number' && record.UniqueBatchId !== uniqueBatchId) {
      totalQuantity += record.Quantity
    }

    if (uniqueBatchId !== null && record.UniqueBatchId === uniqueBatchId && curVal && curQuan) {
      totalMV += curVal
      totalQuantity += curQuan
    } else {
      totalMV += marketValueNumber || notionalNumber
    }
  })

  return { totalMV, totalQuantity }
}
