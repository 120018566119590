import React, { useCallback, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_RATINGS } from '../graphql/queries'
import GenericDropdown from '../dropdown'
import type { RatingDropdownProps } from '../../utility/types'

const RatingDropdown: React.FC<RatingDropdownProps> = ({
  ratingVendor,
  value,
  onChange,
  disabled,
  displayMsg = 'Select'
}) => {
  const [getRatings, { data, loading, error }] = useLazyQuery(GET_RATINGS, {
    variables: { ratingVendor },
    fetchPolicy: 'cache-and-network'
  })

  const fetchRatings = useCallback(() => {
    getRatings()
  }, [getRatings])

  useEffect(() => {
    getRatings()
  }, [ratingVendor, getRatings])

  return (
    <GenericDropdown
      options={data?.getRatingListByVendor?.rating}
      selectedValue={value}
      onChange={(value) => { onChange?.(value) }}
      disabled={disabled ?? loading}
      loading={loading}
      error={error}
      displayMsg={displayMsg}
      fetchOptions={fetchRatings}
    />
  )
}

export default RatingDropdown
