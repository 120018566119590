import React, { type FC, memo, type ReactElement } from 'react'
import { useGraphData } from '../../useGraphData'

const AvatarProfile: FC = (): ReactElement | null => {
  const [graphData, loading] = useGraphData()

  return (
    <nav className="bg-gray-800 text-white px-6 py-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">Nearwater Capital</h1>
        {graphData
          ? <div className="mt-2 text-sm">
              {graphData.displayName}
            </div>
          : loading && <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
        }
      </div>
    </nav>
  )
}

export default memo(AvatarProfile)
