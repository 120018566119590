import React, { type FC } from 'react'
import RecordTable from '../../components/records-table'
import type { CombinedRecordType } from '../../utility/types'
import { TRADER_INPUT_FIELDS_MAP } from '../../utility/constant'
import InputBox from '../../components/inputbox'
import ActionButton from '../../components/action-button'
import closeicon from '../../utility/img/cancel.png'
import { RISK_BOND_EDITABLE_FIELDS } from './utils/constants'
import type { RiskAssetViewerProps } from './utils/types'
import { useRiskAssetDetails } from './hooks/useRiskAssetDetails'
import Message from '../../components/message'
import ConfirmationModal from '../../components/confirm-popup'
import ShowNotes from '../../components/show-notes'
import MiniNotepad from '../../components/notepad'
import { formatInputToDollar } from '../../utility/formatting'

const RiskAssetViewer: FC<RiskAssetViewerProps> = ({
  show,
  onClose,
  batchId,
  batchUniqueId,
  setMessage,
  assetType,
  setMessageColor,
  updateError,
  status,
  basketOverall,
  fundingRequestCode,
  ttlNotional,
  ttlQuantity,
  reconcileIndicator
}) => {
  const {
    records,
    setRecords,
    inputData,
    setInputData,
    isMutating,
    isSecondaryMutating,
    loading,
    error,
    QueryWithRetryComponent,
    enableEdit,
    recalcMessage,
    recalcMessageColor,
    setRecalcMessage,
    dataChanged,
    handleCancel,
    handlePrimaryActionWithValidation,
    handleSecondaryClick,
    handleSave,
    preparingAsset,
    getPrimaryLabel,
    handleDiscard,
    isConfirmationDialogActive,
    setIsConfirmationDialogActive,
    handleSaveExit,
    handleCloseIconClick,
    riskNote,
    setRiskNote,
    displayingNotes
  } = useRiskAssetDetails(
    {
      batchId,
      batchUniqueId,
      assetType,
      setMessage,
      setMessageColor,
      status,
      basketOverall,
      onClose,
      updateError,
      testDetails: true
    }
  )

  if (!show) return null
  if (loading || error) return <QueryWithRetryComponent />

  const approvedStatuses = new Set(['CPTY-APPROVED', 'TRADER-APPROVED-EXCEPTION', 'TRADER-APPROVED'])
  const enableAction = approvedStatuses.has(status) || (assetType === 'BOND' && status === 'ASSET-READY')

  return (
    <>
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-auto">
      {isConfirmationDialogActive && (
        <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      )}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
      <div className="relative bg-white rounded-lg shadow-xl transform transition-all sm:max-w-[70%] sm:my-8 w-auto max-h-[80%]">
      <div className='flex flex-row justify-between items-center px-4 pt-2'>
        <button onClick={handleCloseIconClick} className="transition duration-150 ease-in-out rounded-full p-2">
          <img src={closeicon} alt='Close' width={24} height={24}/>
        </button>
        <div className='flex-grow mx-4 overflow-hidden flex justify-center items-center'>
          {recalcMessage && (
            <div className="overflow-auto whitespace-pre-wrap break-words">
              <Message message={recalcMessage} color={recalcMessageColor} clearMessage={() => { setRecalcMessage('') }} />
            </div>
          )}
        </div>
        {!preparingAsset && enableEdit && (<button
          onClick={handleSave}
          className={`transition ease-in-out bg-green-600 hover:bg-green-700 text-white text-sm py-2 px-3 rounded-full ${dataChanged ? '' : 'opacity-50 cursor-not-allowed'}`}
          disabled={!dataChanged}
        >
          {isMutating
            ? (<div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>)
            : 'Recalc'
          }
        </button>)}
      </div>
      <div className="overflow-auto max-h-[70vh]">
        <InputBox
            fields={TRADER_INPUT_FIELDS_MAP(assetType)}
            inputData={inputData}
            setInputData={setInputData}
            isDataReady={false}
            readOnly={true} />
          <div className='flex flex-col overflow-auto'>
            <div className={fundingRequestCode ? 'px-4' : 'p-4 pt-0'}>
              <RecordTable<CombinedRecordType>
                records={records}
                setRecords={setRecords}
                isDataReady={true}
                editableFields={RISK_BOND_EDITABLE_FIELDS}
                showEdit={enableEdit}
              />
              <div className='flex flex-col xl:flex-row space-y-2.5 xl:space-y-0 xl:space-x-2.5 h-full'>
                <div className="flex flex-1 min-w-0">
                  <ShowNotes data={displayingNotes} />
                </div>
                <div className="flex flex-1 min-w-0">
                  <MiniNotepad
                    noteText={riskNote}
                    setNoteText={setRiskNote}
                    disabled={!enableAction}
                  />
                </div>
                <div className='flex flex-1 flex-col space-y-2.5 xl:space-y-0 xl:flex-row pt-2 min-w-fit'>
                  <div className="flex">
                    Notional MV:
                    <div className='ml-1 mr-2'>
                      <span className='bg-red-100 pl-2 pr-2'>{formatInputToDollar(ttlNotional ?? 0, { prefix: '$' })}</span>
                    </div>
                  </div>
                  <div className="flex">
                    Total Quantity:
                    <div className='ml-1 mr-2'>
                      <span className='bg-red-100 pl-2 pr-2'>{formatInputToDollar(ttlQuantity ?? 0, { digit: 0 })}</span>
                    </div>
                  </div>
                  <div className="flex">
                    Num of assets:
                    <div className='ml-1'>
                      <span className='bg-yellow-100 pl-2 pr-2'>
                        {records.filter(record => !record.isRemoved).length}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className={`${fundingRequestCode ? 'visible' : 'invisible'} flex flex-1 p-5`}>
                Inter-company Loan:
                <div className="ml-1 pl-2 pr-2 mr-2">
                  {fundingRequestCode}
                </div>
              </div>
              {(enableAction || enableEdit) && <div className='flex flex-2 flex-end'>
                <ActionButton
                  primaryLabel={getPrimaryLabel}
                  secondaryLabel={ preparingAsset ? 'Cancel' : 'Reject'}
                  primaryAction={handlePrimaryActionWithValidation}
                  secondaryAction={preparingAsset ? handleCancel : handleSecondaryClick}
                  primaryLoading={isMutating}
                  secondaryLoading={isSecondaryMutating}
                  primaryDisabled={preparingAsset ? !dataChanged : dataChanged}
                />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      isOpen={isConfirmationDialogActive}
      onClose={handleDiscard}
      onConfirm={handleSaveExit}
      onCancel={async () => { setIsConfirmationDialogActive(false) }}
      question="You have some rating changes, do you want to save them?"
      title="Rating Changed"
      confirmText="Save"
      cancelText="Discard"
      primaryLoading={isMutating}
      secondaryLoading={isSecondaryMutating}
    />
    </>
  )
}

export default RiskAssetViewer
