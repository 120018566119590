import React, { useEffect, useState } from 'react'
import { formatKey } from '../../utility/formatting'
import type { InputAssetData, InputBoxProps } from '../../utility/types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { PERCENTAGE_FIELDS } from '../records-table/utilities/constant'

const InputBox: React.FC<InputBoxProps> = ({
  fields,
  inputData,
  setInputData,
  isDataReady = false,
  readOnly = true,
  setMessage,
  setMessageColor,
  setHasFlag
}) => {
  const today = new Date()
  const [dateErrors, setDateErrors] = useState <Partial<InputAssetData>>({})

  useEffect(() => {
    checkAndUpdateDateIssues()
  }, [inputData])

  useEffect(() => {
    setHasFlag?.(Object.keys(dateErrors).length > 0)
  })

  const checkAndUpdateDateIssues = () => {
    const newDateErrors: Partial<Record<string, string>> = {}
    fields.forEach(field => {
      if (field.type === 'date') {
        const checkFieldName = `Check${field.name}` as keyof InputAssetData
        const checkValue = inputData[checkFieldName]
        if (typeof checkValue === 'string' && checkValue !== 'BUSINESS-DAY') {
          const [reason, nextAvailableDate] = checkValue.split('->')
          newDateErrors[field.name as keyof InputAssetData] = `This is ${reason}, change it to ${nextAvailableDate}`
        }
      }
    })
    setDateErrors(newDateErrors)
  }

  const handleChange = (value: string, name: string) => {
    let newValue = value

    if (PERCENTAGE_FIELDS.includes(name)) {
      const numericValue = parseFloat(value)
      if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
        setMessage?.('Percentage value must be between 0 and 100.')
        setMessageColor?.('red')
        return
      } else {
        setMessage?.('')
        newValue = numericValue.toString()
      }
    }
    const newData = { ...inputData, [name]: newValue }

    if (name === 'TradeDate' || name === 'EffectiveDate') {
      const tradeDate = newData.TradeDate
      const effectiveDate = newData.EffectiveDate

      if (tradeDate && effectiveDate && new Date(`${effectiveDate}T00:00`) < new Date(`${tradeDate}T00:00`)) {
        setMessage?.('Effective date must be on or after the trade date.')
        setMessageColor?.('red')
        return
      } else {
        setMessage?.('')
      }
    }
    setInputData?.(newData)
  }

  const handleDateChange = (date: Date | null, name: string) => {
    if (date) {
      handleChange(date.toISOString().split('T')[0], name)
    }
  }

  const isWeekday = (date: Date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  return (
        <div className="flex flex-wrap justify-center mx-3">
            {fields.map((field) => {
              const isPercentageField = PERCENTAGE_FIELDS.includes(field.name)
              const dateValue = inputData[field.name as keyof InputAssetData]
              const dateObject = dateValue ? new Date(`${dateValue}T00:00`) : null
              const error = dateErrors[field.name as keyof InputAssetData]
              const borderStyle = error ? 'border-red-500 border-2 focus:border-red-300 focus:ring-red-200' : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'

              return (
                    <div key={field.name} className="flex items-center max-w-1/4 text-sm relative group">
                        <label className="block font-medium text-gray-700 ml-2 mr-2">
                            {formatKey(field.name)}:
                        </label>
                        <div className="flex-1 my-1">
                            {!field.readOnly && !readOnly
                              ? (

                                  field.type === 'date'
                                    ? (
                                        <DatePicker
                                            todayButton="Today"
                                            selected={dateObject}
                                            onChange={date => { handleDateChange(date, field.name) }}
                                            filterDate={isWeekday}
                                            minDate={today}
                                            showYearDropdown
                                            disabled={!isDataReady}
                                            className={`w-full text-sm rounded-md shadow-sm focus:ring-opacity-100 ${borderStyle} relative`}
                                            dateFormat="yyyy-MM-dd"
                                            yearDropdownItemNumber={20}
                                            scrollableYearDropdown
                                          />
                                      )
                                    : (
                                    <input
                                        type={field.type}
                                        name={field.name}
                                        id={field.name}
                                        value={inputData[field.name as keyof InputAssetData] ?? ''}
                                        onChange={e => { handleChange(e.target.value, e.target.name) }}
                                        placeholder={field.placeholder}
                                        disabled={!isDataReady || readOnly}
                                        className={`w-full block text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${isPercentageField ? 'pr-6' : ''}`}
                                    />
                                      )
                                )
                              : (
                                <span className="text-gray-700 flex items-center px-1 bg-gray-100">
                                    {inputData[field.name as keyof InputAssetData]}
                                    {isPercentageField && ' %'}
                                </span>
                                )}
                            {error && (
                              <div className="overflow-visible absolute -top-1 right-0 bg-red-500 text-white text-xs rounded px-2 py-1 transform translate-y-[-40%] translate-x-[90%] opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                                {error}
                              </div>
                            )}
                            {isPercentageField && !readOnly && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    %
                                </span>
                            )}
                        </div>
                    </div>
              )
            })}
        </div>
  )
}

export default InputBox
